import { useSelector, useDispatch, shallowEqual } from 'react-redux';

// Candidate or Job Intake
const storedSkills = JSON.parse(localStorage.getItem('intake-skills')) || {};

// Candidate Intake
const storedCompleted = JSON.parse(localStorage.getItem('completed-assessments')) || [];

// Job Intake
const storedJobDetailsCompletedSteps = JSON.parse(localStorage.getItem('completed-job-details-steps')) || [];
const storedJobSkillsCompletedSteps = JSON.parse(localStorage.getItem('completed-job-skills-steps')) || [];

const storedAuth = JSON.parse(localStorage.getItem('authentication')) || {};

export const InitialState = {
	// Candidate Intake
	progress: {
		active: null,
		completed: storedCompleted, // an array of step numbers
		canProceed: false,
		isSaving: false,
	},
	// Job Intake
	jobDetailsIntake: {
		activeStep: null,
		completedSteps: storedJobDetailsCompletedSteps, // an array of step numbers
		isSaving: false,
		canProceed: false,
		isComplete: storedJobDetailsCompletedSteps.length === 6,
	},
	// Job Intake
	jobSkillsIntake: {
		activeStep: null,
		completedSteps: storedJobSkillsCompletedSteps, // an array of step numbers
		isSaving: false,
		canProceed: false,
		isComplete: storedJobSkillsCompletedSteps.length === 5,
	},
	role: storedAuth.role || null,
	candidateID: storedAuth.candidate_id || null,
	listingID: storedAuth.listing_id || null,
	contactID: storedAuth.contact_id || null,
	candidate: {},
	listing: {},
	domains: 1,
	matchedSkills: storedSkills.priority_skills && storedSkills.expires > Date.now() ? storedSkills.priority_skills : [],
	suggestedSkills: storedSkills.more_skills && storedSkills.expires > Date.now() ? storedSkills.more_skills : [],
	missingSkills: [],
};

// Action Creators
export const ActionCreators = {
	// Candidate Intake
	setPage: page => ({ type: 'SET_PAGE', page }),
	setCanProceed: proceed => ({ type: 'SET_CAN_PROCEED', proceed }),
	setIsSaving: saving => ({ type: 'SET_IS_SAVING', saving }),
	addCompletedPage: page => ({ type: 'ADD_COMPLETED_PAGE', page }),
	resetAssessment: () => ({ type: 'RESET_ASSESSMENT' }),
	// Job Details Intake
	setJobDetailsActiveStep: step => ({ type: 'SET_JOB_DETAILS_ACTIVE_STEP', step }),
	setJobDetailsCanProceed: proceed => ({ type: 'SET_JOB_DETAILS_CAN_PROCEED', proceed }),
	setJobDetailsIsSaving: saving => ({ type: 'SET_JOB_DETAILS_IS_SAVING', saving }),
	addCompletedJobDetailsStep: step => ({ type: 'ADD_COMPLETED_JOB_DETAILS_STEP', step }),
	setJobDetailsIsComplete: complete => ({ type: 'SET_JOB_DETAILS_IS_COMPLETE', complete }),
	resetJobDetailsIntake: () => ({ type: 'RESET_JOB_DETAILS_INTAKE' }),
	// Job Skills Intake
	setJobSkillsActiveStep: step => ({ type: 'SET_JOB_SKILLS_ACTIVE_STEP', step }),
	setJobSkillsCanProceed: proceed => ({ type: 'SET_JOB_SKILLS_CAN_PROCEED', proceed }),
	setJobSkillsIsSaving: saving => ({ type: 'SET_JOB_SKILLS_IS_SAVING', saving }),
	addCompletedJobSkillsStep: step => ({ type: 'ADD_COMPLETED_JOB_SKILLS_STEP', step }),
	setJobSkillsIsComplete: complete => ({ type: 'SET_JOB_SKILLS_IS_COMPLETE', complete }),
	resetJobSkillsIntake: () => ({ type: 'RESET_JOB_SKILLS_INTAKE' }),

	setCandidateID: id => ({ type: 'SET_CANDIDATE_ID', id }),
	setCandidate: candidate => ({ type: 'SET_CANDIDATE', candidate }),
	updateCandidate: (field, value) => ({ type: 'UPDATE_CANDIDATE', field, value }),
	updateCandidateSkills: skills => ({ type: 'UPDATE_CANDIDATE_SKILLS', skills }),
	updateCandidateSkill: (skill, level) => ({ type: 'UPDATE_CANDIDATE_SKILL', skill, level }),
	setContactID: id => ({ type: 'SET_CONTACT_ID', id }),
	setListingID: id => ({ type: 'SET_LISTING_ID', id }),
	setListing: listing => ({ type: 'SET_LISTING', listing }),
	updateListing: (field, value) => ({ type: 'UPDATE_LISTING', field, value }),
	updateListingSkills: skills => ({ type: 'UPDATE_LISTING_SKILLS', skills }),
	updateListingSkill: (skill, level) => ({ type: 'UPDATE_LISTING_SKILL', skill, level }),
	addDomain: () => ({ type: 'ADD_DOMAIN' }),
	setDomains: amount => ({ type: 'SET_DOMAINS', amount }),
	setMatchedSkills: skills => ({ type: 'SET_MATCHED_SKILLS', skills }),
	removeMatchedSkill: id => ({ type: 'REMOVE_MATCHED_SKILL', id }),
	setSuggestedSkills: skills => ({ type: 'SET_SUGGESTED_SKILLS', skills }),
	addSuggestedSkill: id => ({ type: 'ADD_SUGGESTED_SKILL', id }),
	addMissingSkills: skills => ({ type: 'ADD_MISSING_SKILLS', skills }),
	setMissingSkills: skills => ({ type: 'SET_MISSING_SKILLS', skills }),
};

// Reducer
export function reducer(state = InitialState, action) {
	switch (action.type) {
		// Job Details Actions
		case 'SET_JOB_DETAILS_ACTIVE_STEP':
			return { ...state, jobDetailsIntake: { ...state.jobDetailsIntake, activeStep: action.step } };
		case 'SET_JOB_DETAILS_CAN_PROCEED':
			return { ...state, jobDetailsIntake: { ...state.jobDetailsIntake, canProceed: action.proceed } };
		case 'SET_JOB_DETAILS_IS_SAVING':
			return { ...state, jobDetailsIntake: { ...state.jobDetailsIntake, isSaving: action.saving } };
		case 'ADD_COMPLETED_JOB_DETAILS_STEP':
			if (!state.jobDetailsIntake.completedSteps.includes(action.step)) {
				localStorage.setItem(
					'completed-job-details-steps',
					JSON.stringify([...state.jobDetailsIntake.completedSteps, action.step]),
				);
				return {
					...state,
					jobDetailsIntake: {
						...state.jobDetailsIntake,
						completedSteps: [...state.jobDetailsIntake.completedSteps, action.step],
					},
				};
			}
			return { ...state };
		case 'SET_JOB_DETAILS_IS_COMPLETE':
			return { ...state, jobDetailsIntake: { ...state.jobDetailsIntake, isComplete: action.complete } };
		case 'RESET_JOB_DETAILS_INTAKE':
			localStorage.removeItem('completed-job-details-steps');
			return InitialState;

		// Job Skills Actions
		case 'SET_JOB_SKILLS_ACTIVE_STEP':
			return { ...state, jobSkillsIntake: { ...state.jobSkillsIntake, activeStep: action.step } };
		case 'SET_JOB_SKILLS_CAN_PROCEED':
			return { ...state, jobSkillsIntake: { ...state.jobSkillsIntake, canProceed: action.proceed } };
		case 'SET_JOB_SKILLS_IS_SAVING':
			return { ...state, jobSkillsIntake: { ...state.jobSkillsIntake, isSaving: action.saving } };
		case 'ADD_COMPLETED_JOB_SKILLS_STEP':
			if (!state.jobSkillsIntake.completedSteps.includes(action.step)) {
				localStorage.setItem(
					'completed-job-skills-steps',
					JSON.stringify([...state.jobSkillsIntake.completedSteps, action.step]),
				);
				return {
					...state,
					jobSkillsIntake: {
						...state.jobSkillsIntake,
						completedSteps: [...state.jobSkillsIntake.completedSteps, action.step],
					},
				};
			}
			return { ...state };
		case 'SET_JOB_SKILLS_IS_COMPLETE':
			return { ...state, jobSkillsIntake: { ...state.jobSkillsIntake, isComplete: action.complete } };
		case 'RESET_JOB_SKILLS_INTAKE':
			localStorage.removeItem('completed-job-skills-steps');
			return InitialState;

		// General Assessment Actions
		case 'RESET_ASSESSMENT':
			localStorage.removeItem('intake-skills');
			localStorage.removeItem('completed-assessments');
			return InitialState;
		case 'SET_PAGE':
			return { ...state, progress: { ...state.progress, active: action.page } };
		case 'SET_CAN_PROCEED':
			return { ...state, progress: { ...state.progress, canProceed: action.proceed } };
		case 'SET_IS_SAVING':
			return { ...state, progress: { ...state.progress, isSaving: action.saving } };
		case 'ADD_COMPLETED_PAGE':
			if (!state.progress.completed.includes(action.page)) {
				localStorage.setItem('completed-assessments', JSON.stringify([...state.progress.completed, action.page]));
				return {
					...state,
					progress: { ...state.progress, completed: [...state.progress.completed, action.page] },
				};
			}
			return { ...state };
		case 'SET_CANDIDATE_ID':
			localStorage.setItem(
				'authentication',
				JSON.stringify({
					...JSON.parse(localStorage.getItem('authentication')),
					candidate_id: action.id,
				}),
			);
			return { ...state, candidateID: action.id };
		case 'SET_CANDIDATE':
			return { ...state, candidate: action.candidate };
		case 'UPDATE_CANDIDATE':
			return { ...state, candidate: { ...state.candidate, [action.field]: action.value } };
		case 'UPDATE_CANDIDATE_SKILLS':
			return { ...state, candidate: { ...state.candidate, skills: action.skills } };
		case 'UPDATE_CANDIDATE_SKILL':
			return {
				...state,
				candidate: {
					...state.candidate,
					skills: state.candidate.skills.map(s =>
						s?.skill?.name === action.skill ? { ...s, skill_experience_id: action.level } : s,
					),
				},
			};
		case 'SET_CONTACT_ID':
			localStorage.setItem(
				'authentication',
				JSON.stringify({
					...JSON.parse(localStorage.getItem('authentication')),
					contact_id: action.id,
				}),
			);
			return { ...state, contactID: action.id };
		case 'SET_LISTING_ID':
			localStorage.setItem(
				'authentication',
				JSON.stringify({
					...JSON.parse(localStorage.getItem('authentication')),
					listing_id: action.id,
				}),
			);
			return { ...state, listingID: action.id };
		case 'SET_LISTING':
			return { ...state, listing: action.listing };
		case 'UPDATE_LISTING':
			return { ...state, listing: { ...state.listing, [action.field]: action.value } };
		case 'UPDATE_LISTING_SKILLS':
			return { ...state, listing: { ...state.listing, skills: action.skills } };
		case 'UPDATE_LISTING_SKILL':
			return {
				...state,
				listing: {
					...state.listing,
					skills: state.listing.skills.map(s =>
						s.skill.name === action.skill ? { ...s, skill_experience_id: action.level } : s,
					),
				},
			};
		case 'ADD_DOMAIN':
			return { ...state, domains: state.domains + 1 };
		case 'SET_DOMAINS':
			return { ...state, domains: action.amount };
		case 'SET_MATCHED_SKILLS':
			return { ...state, matchedSkills: action.skills };
		case 'REMOVE_MATCHED_SKILL':
			return {
				...state,
				matchedSkills: state.matchedSkills.filter(s => s.id !== action.id),
			};
		case 'SET_SUGGESTED_SKILLS':
			return { ...state, suggestedSkills: action.skills };
		case 'ADD_SUGGESTED_SKILL':
			return {
				...state,
				matchedSkills: [...state.matchedSkills, state.suggestedSkills.find(s => s.id === action.id)],
				suggestedSkills: state.suggestedSkills.filter(s => s.id !== action.id),
			};
		case 'ADD_MISSING_SKILLS':
			return {
				...state,
				matchedSkills: [...state.matchedSkills, ...action.skills],
				suggestedSkills: state.suggestedSkills.filter(s => s.id !== action.id),
			};
		case 'SET_MISSING_SKILLS':
			return { ...state, missingSkills: action.skills };
		default:
			return state;
	}
}

export function useAssessment() {
	return useSelector(
		state => ({
			// Job Details Progress
			jobDetailsIntake: {
				activeStep: state.assessment.jobDetailsIntake.activeStep,
				completedSteps: state.assessment.jobDetailsIntake.completedSteps,
				isSaving: state.assessment.jobDetailsIntake.isSaving,
				canProceed: state.assessment.jobDetailsIntake.canProceed,
				isComplete: state.assessment.jobDetailsIntake.isComplete,
			},
			// Job Skills Progress
			jobSkillsIntake: {
				activeStep: state.assessment.jobSkillsIntake.activeStep,
				completedSteps: state.assessment.jobSkillsIntake.completedSteps,
				isSaving: state.assessment.jobSkillsIntake.isSaving,
				canProceed: state.assessment.jobSkillsIntake.canProceed,
				isComplete: state.assessment.jobSkillsIntake.isComplete,
			},
			// General Assessment Progress
			progress: state.assessment.progress,
			// Assessment Data
			candidate: state.assessment.candidate,
			listing: state.assessment.listing,
			matchedSkills: state.assessment.matchedSkills,
			suggestedSkills: state.assessment.suggestedSkills,
			missingSkills: state.assessment.missingSkills,
		}),
		shallowEqual,
	);
}

export function useAssessmentActions() {
	const dispatch = useDispatch();

	return {
		// Job Details Actions
		setJobDetailsActiveStep: step => dispatch(ActionCreators.setJobDetailsActiveStep(step)),
		setJobDetailsCanProceed: proceed => dispatch(ActionCreators.setJobDetailsCanProceed(proceed)),
		setJobDetailsIsSaving: saving => dispatch(ActionCreators.setJobDetailsIsSaving(saving)),
		addCompletedJobDetailsStep: step => dispatch(ActionCreators.addCompletedJobDetailsStep(step)),
		setJobDetailsIsComplete: complete => dispatch(ActionCreators.setJobDetailsIsComplete(complete)),
		resetJobDetailsIntake: () => dispatch(ActionCreators.resetJobDetailsIntake()),

		// Job Skills Actions
		setJobSkillsActiveStep: step => dispatch(ActionCreators.setJobSkillsActiveStep(step)),
		setJobSkillsCanProceed: proceed => dispatch(ActionCreators.setJobSkillsCanProceed(proceed)),
		setJobSkillsIsSaving: saving => dispatch(ActionCreators.setJobSkillsIsSaving(saving)),
		addCompletedJobSkillsStep: step => dispatch(ActionCreators.addCompletedJobSkillsStep(step)),
		setJobSkillsIsComplete: complete => dispatch(ActionCreators.setJobSkillsIsComplete(complete)),
		resetJobSkillsIntake: () => dispatch(ActionCreators.resetJobSkillsIntake()),

		// Listing Actions
		setListing: listing => dispatch(ActionCreators.setListing(listing)),
		updateListing: (field, value) => dispatch(ActionCreators.updateListing(field, value)),
		updateListingSkills: skills => dispatch(ActionCreators.updateListingSkills(skills)),
		updateListingSkill: (skill, level) => dispatch(ActionCreators.updateListingSkill(skill, level)),

		// Skills Actions
		setMatchedSkills: skills => dispatch(ActionCreators.setMatchedSkills(skills)),
		removeMatchedSkill: id => dispatch(ActionCreators.removeMatchedSkill(id)),
		setSuggestedSkills: skills => dispatch(ActionCreators.setSuggestedSkills(skills)),
		addSuggestedSkill: id => dispatch(ActionCreators.addSuggestedSkill(id)),
		addMissingSkills: skills => dispatch(ActionCreators.addMissingSkills(skills)),
		setMissingSkills: skills => dispatch(ActionCreators.setMissingSkills(skills)),

		// General Assessment Actions
		resetAssessment: () => dispatch(ActionCreators.resetAssessment()),
	};
}
