import React, { useState, useEffect } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import LabeledField from '../../../form/LabeledField';
import LeadershipAllocationDropdown from '../../../form/dropdowns/LeadershipAllocationDropdown';
import YearsDropdown from '../../../form/dropdowns/YearsDropdown';
import LoadingButton from '../../../form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessment, useAssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';
import * as Utility from '../../../../scripts/utility';
import ExperienceIcon from '../../../icons/ExperienceIcon';
import EducationDropdown from '../../../form/dropdowns/EducationDropdown';
import AreasOfStudyDropdown from '../../../form/dropdowns/AreasOfStudyDropdown';
import DynamicTextEditor from '../../../TextEditor';
import MultiInputIndustryExperienceDropdown from '../../../form/dropdowns/MultiInputIndustryExperienceDropdown';

const PreferredExperienceForm = () => {
	const pageNum = 3;
	const store = useStore();
	const [isLoading, setIsLoading] = useState(false);
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, jobDetailsIntake } = useAssessment();
	const {
		updateListing,
		addCompletedJobDetailsStep,
		setJobDetailsActiveStep,
		setJobDetailsCanProceed,
		setJobDetailsIsSaving,
	} = useAssessmentActions();

	const setListing = ev => updateListing(ev.target.name, ev.target.value);

	const prevStep = () => setJobDetailsActiveStep(pageNum - 1);

	const nextStep = () => {
		if (!jobDetailsIntake.canProceed) {
			store.dispatch(SiteActions.showAlert('Please fill out all required fields.', 'error'));
			return;
		}
		store.dispatch(SiteActions.hideAlert());
		setJobDetailsIsSaving(true);
		const forgedListing = ListingsAPI.getForgedListing(listing);
		ListingsAPI.updateListing(listingID, forgedListing, response => {
			if (!response) {
				setJobDetailsIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			addCompletedJobDetailsStep(pageNum);
			setJobDetailsActiveStep(pageNum + 1);
		});
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		setJobDetailsIsSaving(false);
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			const forgedListing = {
				...response.data.data,
				office_locations: response.data.data.office_locations?.length
					? response.data.data.office_locations
					: [{ city: null, state_id: null }],
			};
			setListing(forgedListing);
			setIsLoading(false);
		});
	}, []);

	// Check if can proceed
	useEffect(() => {
		const canProceed = Boolean(
			listing.professional_experience_years != null &&
				listing.industries?.length > 0 &&
				listing.education_type_id &&
				listing.study_types?.length > 0 &&
				listing.leadership_allocation_type_id &&
				listing.total_leadership_years != null &&
				listing.additional_experience_details &&
				listing.additional_experience_details !== '<p></p>',
		);
		if (canProceed !== jobDetailsIntake.canProceed) {
			setJobDetailsCanProceed(canProceed);
		}
	}, [listing]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ExperienceIcon size={32} className="teal-100" />
				<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
					Job Details
				</Typography>
			</div>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Experience
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				<LabeledField label="Professional Experience" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<YearsDropdown
						variant="outlined"
						name="professional_experience_years"
						value={Utility.getFromObj(listing, 'professional_experience_years')}
						onChange={setListing}
						placeholder="Choose from our list"
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<Fence width={52}>
				<MultiInputIndustryExperienceDropdown onChange={setListing} model={listing} labeledField />
			</Fence>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Education
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				<LabeledField label="Highest Education Level Achieved" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<EducationDropdown
						variant="outlined"
						name="education_type_id"
						value={listing.education_type_id}
						onChange={setListing}
						placeholder="Choose from our list"
						fullWidth
					/>
				</LabeledField>
				<LabeledField label="Preferred Areas of Study" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<AreasOfStudyDropdown
						variant="outlined"
						name="study_types"
						value={listing?.study_types?.map(t => (t.id ? t.id : t))}
						onChange={setListing}
						fullWidth
						placeholder={listing?.study_types?.length > 0 ? '' : 'Select all that apply'}
						multiple
					/>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Leadership
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				<LabeledField
					label="Leadership Time Allocation"
					info="How much time do you anticipate this role will spend on leadership responsibilities?"
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
				>
					<LeadershipAllocationDropdown
						variant="outlined"
						name="leadership_allocation_type_id"
						value={Utility.getFromObj(listing, 'leadership_allocation_type_id')}
						placeholder="Choose from our list"
						onChange={setListing}
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Leadership Experience
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				{/* ! 'Min Req'd Leadership Exp' goes here? check google sheet
				 (https://docs.google.com/spreadsheets/d/1WnA6qPcyvKTcRBZ9bsMCaFw1RMFT9Gb7CTUahy6tFfo/edit?gid=0#gid=0) */}
				<LabeledField
					label="Minimum Years of Leadership Experience"
					labelStyle={{ fontWeight: '500', fontSize: 14 }}
					removeMargin
				>
					<YearsDropdown
						variant="outlined"
						name="total_leadership_years"
						value={Utility.getFromObj(listing, 'total_leadership_years')}
						placeholder="Choose from our list"
						onChange={setListing}
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={3} />
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Additional Experience Detail
			</Typography>
			<VerticalSpacer height={2} />
			<LabeledField
				label="Additional Experience Detail"
				labelStyle={{ fontWeight: '500', fontSize: 14 }}
				enableMinimize
				minimized={Boolean(listing.additional_experience_details)}
			>
				<DynamicTextEditor
					fieldName="additional_experience_details"
					content={listing.additional_experience_details || ''}
					onChange={setListing}
				/>
			</LabeledField>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" disabled={jobDetailsIntake.isSaving} onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					size="large"
					loading={jobDetailsIntake.isSaving}
					disabled={!jobDetailsIntake.canProceed || jobDetailsIntake.isSaving}
					onClick={nextStep}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

PreferredExperienceForm.propTypes = {};

PreferredExperienceForm.defaultProps = {};

export default PreferredExperienceForm;
