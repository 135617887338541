import React, { useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../form/LoadingButton';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import PreferencesIcon from '../../../icons/PreferencesIcon';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessment, useAssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';

const PreferredSkillsForm = ({ prevStep, setStoryStep }) => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, jobSkillsIntake } = useAssessment();
	const { setJobSkillsCanProceed, setJobSkillsIsSaving, updateListingSkills } = useAssessmentActions();
	const skillStoryName = 'preferred_skill';
	const preferredSkills = useMemo(() => listing?.skills?.filter(s => s[skillStoryName]), [listing.skills]);

	const handleSelectSkill = skill => {
		const updatedSkills = listing.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...s, [skillStoryName]: !s[skillStoryName] } : s,
		);
		updateListingSkills(updatedSkills);
		if (preferredSkills.length > 0) {
			setJobSkillsCanProceed(true);
		}
	};

	const nextStep = () => {
		store.dispatch(SiteActions.hideAlert());
		setJobSkillsIsSaving(true);
		ListingsAPI.addListingSkills(listingID, listing.skills, response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				setJobSkillsIsSaving(false);
				return;
			}
			updateListingSkills(response.data.data);
			setJobSkillsIsSaving(false);
			setStoryStep(s => s + 1);
		});
	};

	// Check if can proceed
	useEffect(() => {
		const canProceed = preferredSkills?.length >= 1;
		if (canProceed !== jobSkillsIntake.canProceed) {
			setJobSkillsCanProceed(canProceed);
		}
	}, [preferredSkills]);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Your Skill Story <span className="color-muted">3 of 4</span>
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Let&rsquo;s further refine the job skills into categories that will help generate the most precise candidate
				matches - <strong>Preferred</strong> skills.
			</Typography>
			<VerticalSpacer height={2} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item className="text-center">
						<PreferencesIcon className="teal-100" size={26} style={{ marginTop: 4, marginRight: 15 }} />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle2" className="subtitle-2-bold">
							Preferred Skills
						</Typography>
						<Typography>
							What are the preferred skills that will help separate top-tier candidates from mid-level candidates?
						</Typography>
						<VerticalSpacer height={2} />
						{listing?.skills
							?.filter(s => s.skill_experience_id)
							?.filter(s => !s.required_skill)
							.map(skill => (
								<Chip
									key={skill.skill_id}
									label={skill.skill.label}
									color={preferredSkills.find(s => s.skill_id === skill.skill_id) ? 'primary' : 'default'}
									onClick={() => handleSelectSkill(skill)}
								/>
							))}
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={() => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" disabled={jobSkillsIntake.isSaving} onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					size="large"
					loading={jobSkillsIntake.isSaving}
					disabled={!jobSkillsIntake.canProceed}
					onClick={nextStep}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

PreferredSkillsForm.propTypes = {
	prevStep: PropTypes.func,
	setStoryStep: PropTypes.func,
};

PreferredSkillsForm.defaultProps = {
	prevStep: () => null,
	setStoryStep: () => null,
};

export default PreferredSkillsForm;
