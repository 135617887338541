import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useStore, useSelector, shallowEqual } from 'react-redux';
import { Grid, Typography, ButtonBase, IconButton } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import LabeledField from '../../../components/form/LabeledField';
import VerticalSpacer from '../../../components/VerticalSpacer';
import ElasticSkillDropdown from '../../../components/form/dropdowns/ElasticSkillDropdown';
import * as ListingsAPI from '../../../scripts/listings';
import * as Utility from '../../../scripts/utility';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditJobBlockModal from './EditJobBlockModal';
import MeterIcon from '../../../components/icons/SectionIcons/MeterIcon';
import Fence from '../../../components/Fence';
import YearsDropdown from '../../../components/form/dropdowns/YearsDropdown';

const EditModal = ({ job, setJob }) => {
	const [minimizeUncategorized, setMinimizeUncategorized] = useState(true);
	const [updatedJob, setUpdatedJob] = useState(job);
	const store = useStore();
	const dispatch = useDispatch();
	const { data } = useSelector(state => state.site, shallowEqual);
	const [showHelp, setShowHelp] = useState(false);

	// Collect all selected skill IDs
	const allSelectedSkillIds = useMemo(() => updatedJob.skills.map(s => s.skill_id), [updatedJob.skills]);

	// Function to update skills based on experience level
	const updateSkills = (levelId, skillIds, skillCache) => {
		// Remove skills from other levels
		const filteredSkills = updatedJob.skills.filter(
			s =>
				(s.skill_experience_id !== levelId && !skillIds.includes(s.skill_id)) ||
				(s.skill_experience_id === levelId && skillIds.includes(s.skill_id)),
		);

		// Create new skills for the current level
		const newSkills = skillIds
			.filter(skillId => !filteredSkills.some(s => s.skill_id === skillId))
			.map(skillId => {
				const existingSkill = updatedJob.skills.find(s => s.skill_id === skillId);
				return {
					skill_id: skillId,
					skill: skillCache?.[skillId] || existingSkill?.skill || { id: skillId },
					skill_experience_id: levelId,
					experience_years: existingSkill ? existingSkill.experience_years : 0,
				};
			});

		setUpdatedJob({
			...updatedJob,
			skills: [...filteredSkills, ...newSkills],
		});
	};

	// Other functions (selectHeroSkill, setSkillExperience, selectSkill, handleUncategorizedChange)
	const heroSkills = useMemo(() => updatedJob?.skills?.filter(skill => skill.hero_skill), [updatedJob.skills]);
	const requiredSkills = useMemo(() => updatedJob?.skills?.filter(skill => skill.required_skill), [updatedJob.skills]);
	const preferredSkills = useMemo(() => updatedJob?.skills?.filter(skill => skill.preferred_skill), [
		updatedJob.skills,
	]);
	const bonusSkills = useMemo(() => updatedJob?.skills?.filter(skill => skill.bonus_skill), [updatedJob.skills]);

	const validPreferredSkills = updatedJob?.skills?.filter(
		x => (x.skill_experience_id || x.experience) && !x.required_skill && !x.bonus_skill,
	);

	const validRequiredSkills = updatedJob?.skills?.filter(
		x => (x.skill_experience_id || x.experience) && !x.preferred_skill && !x.bonus_skill,
	);

	const validHeroSkills = updatedJob?.skills?.filter(
		x => x.skill_experience_id && !x.preferred_skill && !x.required_skill && !x.bonus_skill,
	);

	const unCategorizedSkills = useMemo(
		() =>
			updatedJob?.skills?.filter(
				skill =>
					(!skill.skill_experience_id || skill.skill_experience_id === 1) &&
					!skill.hero_skill &&
					!skill.required_skill &&
					!skill.preferred_skill &&
					!skill.bonus_skill,
			),
		[updatedJob?.skills],
	);

	const selectHeroSkill = (skillId, replacedSkillId) => {
		const updatedSkills = updatedJob.skills.map(s => {
			if (s.skill_id === replacedSkillId) return { ...s, hero_skill: false };
			if (s.skill_id === skillId) return { ...s, hero_skill: true };
			return s;
		});
		setUpdatedJob(s => ({ ...s, skills: updatedSkills }));
	};

	const setSkillExperience = (skill, experienceYears) => {
		const updatedSkills = updatedJob.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...skill, experience_years: experienceYears } : s,
		);
		setUpdatedJob(c => ({
			...c,
			skills: updatedSkills,
		}));
	};

	const selectSkill = (skillStoryName, skillIds, skillCache) => {
		// Create an array of skills that are new; not already in updatedJob.skills
		const newSkills = skillIds
			.filter(sid => !updatedJob.skills.find(s => s.skill_id === sid))
			.map(sid => {
				return {
					skill_id: sid,
					[skillStoryName]: true,
					skill_experience_id: 1,
					experience_years: 0,
					skill: skillCache[sid] || { id: sid },
				};
			});
		// For each input skill (skillIds) set the listing.skill story name to true
		const updatedSkills = updatedJob.skills
			.map(s => ({ ...s, [skillStoryName]: skillIds.includes(s.skill_id) }))
			.concat(newSkills);

		setUpdatedJob(c => ({ ...c, skills: updatedSkills }));
	};

	const handleUncategorizedChange = (skillStoryName, skillIds) => {
		const removedSkillIds = unCategorizedSkills.filter(s => !skillIds.includes(s.skill_id)).map(s => s.skill_id);

		const updatedSkills = updatedJob.skills.filter(s => !removedSkillIds.includes(s.skill_id));

		setUpdatedJob(c => ({ ...c, skills: updatedSkills }));
	};

	return (
		<EditJobBlockModal
			job={job}
			setJob={setJob}
			title="Skills"
			icon={MeterIcon}
			overrideSaveJob={({ setIsSaving, updatedJob }) => {
				setIsSaving(true);
				const forgedListing = ListingsAPI.getForgedListing(updatedJob);
				const forgedCurrentListing = ListingsAPI.getForgedListing(job);
				const differencesToSubmit = Utility.getEntityDifferences(forgedCurrentListing, forgedListing);

				ListingsAPI.updateListing(job.id, differencesToSubmit, response => {
					if (!response) {
						setIsSaving(false);
						store.dispatch(
							SiteActions.showAlert('An error occurred saving your information. Please try again.', 'error'),
						);
						return;
					}
					const newListing = response.data.data;
					ListingsAPI.syncListingSkills(job.id, updatedJob.skills, skillResponse => {
						if (!skillResponse) {
							setIsSaving(false);
							store.dispatch(
								SiteActions.showAlert('An error occurred saving your information. Please try again.', 'error'),
							);
							return;
						}
						newListing.skills = skillResponse.data.data;
						setJob(newListing);
						dispatch(SiteActions.hideModal());
					});
				});
			}}
			renderContents={() => {
				return (
					<>
						{[...data.skillExperienceLevels]
							.reverse()
							.filter(x => x.id !== 1) /* Filter out the "Uncategorized" experience level. */
							.map(level => (
								<LabeledField
									key={level.name}
									label={
										{
											Best: 'Best Skills',
											Advanced: 'Advanced Skills',
											Intermediate: 'Intermediate Skills',
											Basic: 'Basic Skills',
										}[level.label] || level.label
									}
									// required
								>
									<ElasticSkillDropdown
										filterListingId={job.id}
										tags
										variant="outlined"
										color="primary"
										name={`skills_${level.name}`}
										value={updatedJob.skills.filter(s => s.skill_experience_id === level.id).map(s => s.skill_id)}
										getCategoryLabel={skillId => {
											return updatedJob?.skills?.find(
												s2 => s2.skill_id === skillId && (!s2.skill_experience_id || s2.skill_experience_id === 1),
											)
												? 'Suggested'
												: 'All';
										}}
										onChange={(e, skillCache) => {
											updateSkills(level.id, e.target.value, skillCache);
										}}
										fullWidth
									/>
								</LabeledField>
							))}
						<br />

						{/* Skill Story Section */}
						<Typography variant="h3" className="text-left text-medium">
							Skill Story
						</Typography>
						<VerticalSpacer height={1} />
						<Typography className="text-bold">Required Skills</Typography>
						<Typography>
							From the skill pool you provided, please select the skills that a candidate MUST possess to be considered
							for this role.
						</Typography>
						<VerticalSpacer height={1} />
						<ElasticSkillDropdown
							filterListingId={job.id}
							tags
							variant="outlined"
							color="primary"
							name="required_skill"
							value={requiredSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={validRequiredSkills}
							onChange={(ev, skillCache) => selectSkill(ev.target.name, ev.target.value, skillCache)}
							fullWidth
						/>
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Hero Skills</Typography>
						<Typography>
							Please select up to three skills that are viewed as the most critical. These are the skills that are most
							important to successful performance in this role.
						</Typography>
						<VerticalSpacer height={1} />
						{[1, 2, 3].map((v, i) => {
							return (
								<Grid container spacing={2} key={`heroSkill-${heroSkills[i]?.skill_id || v}`}>
									<Grid item xs={7}>
										<ElasticSkillDropdown
											filterListingId={job.id}
											variant="outlined"
											name="hero_skill"
											value={heroSkills[i]?.skill_id}
											override={validHeroSkills}
											onChange={ev => selectHeroSkill(ev.target.value, heroSkills[i]?.skill_id)}
											fullWidth
										/>
									</Grid>
									<Grid item xs={2} style={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-end' }}>
										<Typography variant="body1" className="text-right text-medium">
											Experience
										</Typography>
									</Grid>
									<Grid item xs={3}>
										<YearsDropdown
											variant="outlined"
											name="hero_skill_years"
											value={heroSkills[i]?.experience_years}
											onChange={ev => setSkillExperience(heroSkills[i], ev.target.value)}
											disableClearable={false}
											fullWidth
										/>
									</Grid>
								</Grid>
							);
						})}
						<VerticalSpacer height={0.5} />
						<ButtonBase onClick={() => setShowHelp(s => !s)} className="button-text-muted">
							Don&lsquo;t See a Skill You&lsquo;re Looking For?
						</ButtonBase>
						{showHelp && (
							<Fence
								style={{
									background: '#E6F1FF',
									borderRadius: 6,
									padding: '46px 30px 28px 30px',
									position: 'relative',
								}}
							>
								<IconButton
									className="modal-close modal-close-primary"
									onClick={() => setShowHelp(false)}
									style={{ marginTop: '-15px', marginRight: '-15px' }}
								>
									<Icons.Close />
								</IconButton>
								<Typography className="text-bold" style={{ marginTop: -18 }}>
									Not seeing a Hero Skill?
								</Typography>
								<Typography className="grey-300">
									Skills must be ranked Best or Advanced (in the Skills section) before they can be selected as Hero
									skills.
									<br />
									In order to add a new Hero skill, you must first rank it accordingly.
								</Typography>
							</Fence>
						)}
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Preferred Skills</Typography>
						<Typography>
							What are the preferred skills that will help separate top-tier candidates from mid-level candidates?
						</Typography>
						<VerticalSpacer height={1} />
						<ElasticSkillDropdown
							filterListingId={job.id}
							tags
							variant="outlined"
							color="primary"
							name="preferred_skill"
							value={preferredSkills.map(s => (s.skill_id ? s.skill_id : s))}
							override={validPreferredSkills}
							onChange={(ev, skillCache) => selectSkill(ev.target.name, ev.target.value, skillCache)}
							fullWidth
						/>
						<VerticalSpacer height={3} />
						<Typography className="text-bold">Bonus Skills</Typography>
						<Typography>What skills, in or outside of the role scope, do you view as bonus skills?</Typography>
						<VerticalSpacer height={1} />
						<ElasticSkillDropdown
							filterListingId={job.id}
							tags
							variant="outlined"
							color="primary"
							name="bonus_skill"
							value={bonusSkills.map(s => (s.skill_id ? s.skill_id : s))}
							filter={skillId => {
								// Valid bonus skills are any skill that doesn't already have a skill story for this job.
								const skillOnJob = updatedJob.skills.find(s => s.skill_id === skillId);
								if (skillOnJob) {
									// If the skill has another skill story, it's not a valid bonus skill.
									if (skillOnJob.preferred_skill || skillOnJob.required_skill || skillOnJob.hero_skill) {
										return false;
									}
								}
								return !allSelectedSkillIds.includes(skillId);
							}}
							getCategoryLabel={skillId => {
								return updatedJob?.skills?.find(
									s2 => s2.skill_id === skillId && (!s2.skill_experience_id || s2.skill_experience_id === 1),
								)
									? 'Suggested'
									: 'All';
							}}
							onChange={(ev, skillCache) => selectSkill(ev.target.name, ev.target.value, skillCache)}
							fullWidth
						/>

						<VerticalSpacer height={3} />
						<Typography
							onClick={() => {
								setMinimizeUncategorized(!minimizeUncategorized);
							}}
							className="text-bold"
							style={{ cursor: 'pointer' }}
						>
							Uncategorized {minimizeUncategorized ? '►' : '▼'}
						</Typography>

						{!minimizeUncategorized && (
							<>
								<Typography>
									Skills attached to the candidate that do not have a skill level or skill story. Removing them deletes
									them from the candidate completely.
								</Typography>

								<VerticalSpacer height={1} />
								<ElasticSkillDropdown
									filterListingId={job.id}
									tags
									variant="outlined"
									color="primary"
									name="uncategorized"
									value={[...unCategorizedSkills]
										?.sort((a, b) => a?.skill?.name?.localeCompare(b?.skill?.name))
										.map(s => (s.skill_id ? s.skill_id : s))}
									onChange={ev => handleUncategorizedChange(ev.target.name, ev.target.value)}
									fullWidth
								/>
							</>
						)}
						<br />
					</>
				);
			}}
		/>
	);
};

EditModal.propTypes = {
	job: PropTypes.shape({ id: PropTypes.string }),
	setJob: PropTypes.func,
};

EditModal.defaultProps = {
	job: {},
	setJob: () => null,
};

export default EditModal;
