import React from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import Dropdown from './Dropdown';

const SalaryRangeDropdown = props => {
	const { data } = useSelector(state => state.site, shallowEqual);
	const { value } = props;

	const salaryList = [...data.salaryExpectationTypes];
	const selectedValue = salaryList.find(option => parseInt(option.name, 10) === value)?.id;

	return <Dropdown {...props} data={salaryList} value={selectedValue} />;
};

export default SalaryRangeDropdown;
