/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Popper, Paper, Typography, Tooltip, Button } from '@material-ui/core';
import * as Icons from '@material-ui/icons';
import Fence from '../Fence';

const Page = ({
	label,
	info,
	color,
	close,
	removeMargin,
	required,
	className,
	labelStyle,
	containerStyle,
	children,
	labelAddendum,
	labelAddendumTooltip,
	// Newly added minimized props for hiding children content 11/6/24
	enableMinimize = false,
	minimized: _minimized = false,
	...props
}) => {
	const [popper, setPopper] = useState({ open: false, anchor: null });
	const [minimized, setMinimized] = useState(_minimized);

	const labelAddendumDisplay = (
		<Tooltip title={labelAddendumTooltip || ''}>
			<Typography
				variant="caption"
				style={{
					marginLeft: '10px',
					backgroundColor: 'lightgray',
					padding: '4px 8px',
					borderRadius: '12px',
					color: 'black',
				}}
			>
				{labelAddendum}
			</Typography>
		</Tooltip>
	);

	return (
		<div
			className={`labeled-field ${close ? 'close' : ''} ${removeMargin ? 'no-margins' : ''}`}
			style={{
				...(containerStyle || {}),
			}}
		>
			<label
				className={`field-label ${close ? 'close' : ''} ${className}`}
				style={{ fontSize: 12, ...labelStyle }}
				{...props}
			>
				{required ? `${label} *` : label}
				{info ? (
					<>
						{' '}
						<Icons.Info
							color="disabled"
							onFocus={ev => setPopper({ open: true, anchor: ev.currentTarget })}
							onBlur={ev => setPopper({ open: false, anchor: ev.currentTarget })}
							onMouseOver={ev => setPopper({ open: true, anchor: ev.currentTarget })}
							onMouseOut={ev => setPopper({ open: false, anchor: ev.currentTarget })}
						/>
						<Popper open={popper.open} anchorEl={popper.anchor} placement="right-start" transition>
							<Paper color="primary">
								<Fence width={17}>{info}</Fence>
							</Paper>
						</Popper>
					</>
				) : null}
				{labelAddendum && labelAddendumDisplay}
				{enableMinimize && (
					<Button
						variant="text"
						size="small"
						onClick={() => setMinimized(!minimized)}
						style={{
							padding: '6px 6px',
							minWidth: 0,
							margin: 6,
							marginBottom: 10,
						}}
					>
						<Icons.ChevronRight
							style={{
								fontSize: 24,
								color: 'var(--primaryTextColor)',
								marginRight: 0,
								transform: `rotate(${minimized ? 0 : 90}deg)`,
								transition: 'transform .2s ease',
							}}
						/>
					</Button>
				)}
			</label>
			{!minimized && children}
		</div>
	);
};

Page.propTypes = {
	label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
	info: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
	color: PropTypes.string,
	close: PropTypes.bool,
	removeMargin: PropTypes.bool,
	required: PropTypes.bool,
	className: PropTypes.string,
	children: PropTypes.node,
};

Page.defaultProps = {
	info: '',
	color: '',
	close: false,
	removeMargin: false,
	required: false,
	className: '',
	children: [],
};

export default Page;
