import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useStore, useSelector, shallowEqual } from 'react-redux';
import {
	Typography,
	RadioGroup,
	TextField,
	Radio,
	Chip,
	FormControlLabel,
	Button,
	CircularProgress,
} from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import UploadIcon from '../../../../icons/UploadIcon';
import * as CandidatesAPI from '../../../../../scripts/candidates';
import { ActionCreators as SiteActions } from '../../../../../store/Site';
import * as Utility from '../../../../../scripts/utility';
import { ActionCreators as AssessmentActions } from '../../../../../store/Assessment';
import VerticalSpacer from '../../../../VerticalSpacer';
import LabeledField from '../../../../form/LabeledField';
import Fence from '../../../../Fence';
import ExperienceIcon from '../../../../icons/ExperienceIcon';
import LoadingButton from '../../../../form/LoadingButton';

const ResumeForm = () => {
	const pageNum = 2;
	const [isLoading, setIsLoading] = useState(false);
	const store = useStore();
	const { id: candidateID } = useParams();

	const { alert } = useSelector(state => state.site, shallowEqual);
	const { candidate, progress } = useSelector(state => state.assessment, shallowEqual);
	const [candidateBeforeUpdate, setCandidateBeforeUpdate] = useState({});
	const [isUploadSectionVisible, setIsUploadShown] = useState(false);
	const [uploadOrPasteResume, setUploadOrPasteResume] = useState('1');
	const [resumes, setResumes] = useState([]);

	const setCandidate = ev => {
		store.dispatch(AssessmentActions.updateCandidate(ev.target.name, ev.target.value));
		if (ev.target.name === 'resumeFile' && ev.target.value)
			store.dispatch(AssessmentActions.updateCandidate('resume', ''));
	};

	const onResumeUpload = (response, err) => {
		if (!response) {
			store.dispatch(AssessmentActions.setIsSaving(false));
			if (Utility.getFromObj(err, 'response', 'data', 'errors', 'file')) {
				store.dispatch(
					SiteActions.showAlert(
						`An error ocurred saving the resume. ${Utility.getFromObj(err, 'response', 'data', 'errors', 'file', 0)}`,
						'error',
					),
				);
			} else {
				store.dispatch(SiteActions.showAlert('An error ocurred saving the resume. Please try again.', 'error'));
			}
			return;
		}
		const moreSkills = [
			...(response?.data?.more_skills || []),
			...(response?.data?.view_more_1 || []),
			...(response?.data?.view_more_2 || []),
			...(response?.data?.view_more_3 || []),
		];
		// Store in local storage in case we refresh the page
		localStorage.setItem(
			'intake-skills',
			JSON.stringify({
				priority_skills: response?.data?.priority_skills,
				more_skills: moreSkills,
				expires: Date.now() + 3600000,
			}),
		);
		if (Utility.getFromObj(response, 'data', 'priority_skills')) {
			store.dispatch(AssessmentActions.setMatchedSkills(response.data.priority_skills));
		}
		if (Utility.getFromObj(response, 'data', 'more_skills')) {
			store.dispatch(AssessmentActions.setSuggestedSkills(moreSkills));
		}
		store.dispatch(AssessmentActions.addCompletedPage(pageNum));
		store.dispatch(AssessmentActions.setPage(pageNum + 0.5));
	};

	const prevStep = () => store.dispatch(AssessmentActions.setPage(pageNum - 1));

	const nextStep = () => {
		if (!progress.canProceed) {
			store.dispatch(SiteActions.showAlert('Please upload a valid resume.', 'error'));
			return;
		}
		store.dispatch(SiteActions.hideAlert());
		store.dispatch(AssessmentActions.setIsSaving(true));

		const forgedOriginalCandidate = CandidatesAPI.getForgedCandidate(candidateBeforeUpdate);
		const forgedCurrentCandidate = CandidatesAPI.getForgedCandidate(candidate);

		let differencesToSubmit;
		if (!isUploadSectionVisible) {
			differencesToSubmit = Utility.getEntityDifferences(forgedOriginalCandidate, forgedOriginalCandidate);
		} else {
			differencesToSubmit = Utility.getEntityDifferences(forgedOriginalCandidate, forgedCurrentCandidate);
		}

		CandidatesAPI.updateCandidate(candidateID, differencesToSubmit, response => {
			if (!response) {
				store.dispatch(AssessmentActions.setIsSaving(false));
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			if (candidate.resumeFile && uploadOrPasteResume === '1') {
				CandidatesAPI.uploadCandidateResume(candidateID, candidate.resumeFile, onResumeUpload);
			} else if (candidate.resume && uploadOrPasteResume === '2') {
				CandidatesAPI.uploadCandidateTextResume(candidateID, candidate.resume, onResumeUpload);
			} else {
				onResumeUpload(true);
			}
		});
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		store.dispatch(AssessmentActions.setIsSaving(false));
		CandidatesAPI.getCandidate(candidateID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			store.dispatch(AssessmentActions.setCandidate(response.data.data));
			setCandidateBeforeUpdate(response.data.data);
			setIsLoading(false);
		});
		CandidatesAPI.getCandidateResumes(
			candidateID,
			{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
			response => {
				if (!response) {
					store.dispatch(
						SiteActions.showAlert('Could not load the candidate. Refresh the page to try again.', 'error'),
					);
					return;
				}
				setResumes(response.data.data);
				setIsUploadShown(response.data.data.length === 0);
			},
		);
	}, []);

	// Check if can proceed
	useEffect(() => {
		let canProceed;

		if (!isUploadSectionVisible && resumes.length > 0) {
			canProceed = true;
		} else if (isUploadSectionVisible && resumes.length === 0) {
			canProceed = Boolean(candidate.resume || candidate.resumeFile);
		} else {
			canProceed =
				(candidateBeforeUpdate.resume !== candidate.resume && Boolean(candidate.resume)) ||
				(candidateBeforeUpdate.resumeFile !== candidate.resumeFile && candidate.resumeFile != null);
		}
		if (canProceed !== progress.canProceed) {
			store.dispatch(AssessmentActions.setCanProceed(canProceed));
		}
	}, [candidate, isUploadSectionVisible]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ExperienceIcon size={32} className="teal-100" />
				<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
					Experience <span className="color-muted">1 of 5</span>
				</Typography>
			</div>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Resume / CV
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Please upload your current resume or select the copy/paste option and enter your resume text.
			</Typography>
			<VerticalSpacer height={1} />
			{!isUploadSectionVisible &&
				resumes?.map(resume => (
					<Typography key={resume.document_id}>
						Resume <b>{resume.file_name}</b> was uploaded{' '}
						<b>{Utility.formatDate(resume.updated_at, false, 'MMMM D, YYYY')}</b>
					</Typography>
				))}
			<VerticalSpacer height={1} />
			<VerticalSpacer height={1} />
			{resumes.length > 0 && (
				<Button variant="outlined" color="primary" size="large" onClick={() => setIsUploadShown(prev => !prev)}>
					{isUploadSectionVisible ? 'Keep existing resume' : 'Upload a new resume'}
				</Button>
			)}
			<VerticalSpacer height={1} />
			{isUploadSectionVisible && (
				<Fence width={55}>
					<RadioGroup
						row
						aria-label="Upload or paste text?"
						name="upload_or_paste_resume"
						onChange={e => setUploadOrPasteResume(e.currentTarget.value)}
						value={uploadOrPasteResume}
					>
						<FormControlLabel value="1" control={<Radio color="primary" />} label="Upload Resume" />
						<FormControlLabel value="2" control={<Radio color="primary" />} label="Copy &amp; Paste Resume" />
					</RadioGroup>
					<VerticalSpacer height={1} />
					{uploadOrPasteResume === '1' ? (
						<>
							<LabeledField label="Upload Resume">
								<DropzoneAreaBase
									name="resumeFile"
									dropzoneText="CLICK OR DRAG & DROP TO UPLOAD"
									Icon={UploadIcon}
									dropzoneClass={`file-upload-dropzone ${candidate.resumeFile && 'dropzone-disabled'}`}
									onAdd={files => store.dispatch(AssessmentActions.updateCandidate('resumeFile', files[0].file))}
									filesLimit={1}
									showAlerts={false}
									showPreviewsInDropzone={false}
									dropzoneProps={{ disabled: candidate.resumeFile }}
								/>
								{candidate.resumeFile && (
									<Chip
										label={candidate.resumeFile.name}
										onDelete={() => setCandidate({ target: { name: 'resumeFile', value: null } })}
									/>
								)}
							</LabeledField>
						</>
					) : (
						<LabeledField label="Resume / CV">
							<TextField variant="outlined" name="resume" onChange={setCandidate} minRows={10} multiline fullWidth />
						</LabeledField>
					)}
				</Fence>
			)}
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					loading={progress.isSaving}
					color="primary"
					size="large"
					disabled={!progress.canProceed}
					onClick={progress.canProceed ? nextStep : () => null}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

export default ResumeForm;
