import React from 'react';
import PropTypes from 'prop-types';
import { useStore } from 'react-redux';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import WelcomeForm from './WelcomeForm';
import JobDescriptionForm from './JobDescriptionForm';
import JobDetailsForm from './JobDetailsForm';
import SkillsForm from './SkillsForm';
import SkillRankingsForm from './SkillRankingsForm';
import SkillStoryForm from './SkillStoryForm';
import VerticalSpacer from '../../../VerticalSpacer';
import * as Utility from '../../../../scripts/utility';
import PreferredExperienceForm from './PreferredExperienceForm';
import WorkLocationForm from './WorkLocationForm';
import JobQAForm from './JobQAForm';
import { useAssessment, useAssessmentActions } from '../../../../store/Assessment';

const JobAssessmentModal = ({ setJobProfile, getJobDescriptions, path }) => {
	const { jobDetailsIntake, jobSkillsIntake } = useAssessment();
	const { setJobDetailsActiveStep, setJobSkillsActiveStep } = useAssessmentActions();
	const store = useStore();

	const getStepIcon = state => {
		const cn = `step-icon ${state.active ? 'step-icon-active' : ''} ${state.completed ? 'step-icon-completed' : ''}`;
		return <span className={cn} />;
	};

	console.log({ path });

	const renderForm = () => {
		if (path === 'jobDetails') {
			switch (jobDetailsIntake.activeStep) {
				case 1:
					return <JobDescriptionForm path={path} />;
				case 2:
					return <JobDetailsForm />;
				case 3:
					return <PreferredExperienceForm />;
				case 4:
					return <WorkLocationForm />;
				case 5:
					return <JobQAForm />;
				default:
					return <WelcomeForm />;
			}
		}

		if (path === 'jobSkills') {
			switch (jobSkillsIntake.activeStep) {
				case 1:
					return <JobDescriptionForm path={path} />;
				case 2:
					return <SkillsForm />;
				case 3:
					return <SkillRankingsForm />;
				case 4:
					return <SkillStoryForm setJobProfile={setJobProfile} getJobDescriptions={getJobDescriptions} />;
				default:
					return <WelcomeForm />;
			}
		}

		return <WelcomeForm />;
	};

	const isJobDetailsActive = jobDetailsIntake.activeStep !== null && jobSkillsIntake.activeStep === null;
	const isJobSkillsActive = jobSkillsIntake.activeStep !== null && jobDetailsIntake.activeStep === null;

	const isJobDetailsStepNotComplete = step => {
		if (!jobDetailsIntake.completedSteps.includes(step)) {
			return true;
		}
		return false;
	};

	const isJobSkillsStepNotComplete = step => {
		if (!jobSkillsIntake.completedSteps.includes(step)) {
			return true;
		}
		return false;
	};

	return (
		<div className="assessment">
			{isJobDetailsActive && (
				<Stepper activeStep={jobDetailsIntake.activeStep - 1} alternativeLabel>
					<Step>
						<StepLabel
							StepIconComponent={getStepIcon}
							onClick={() => {
								if (isJobDetailsStepNotComplete(0)) return;
								Utility.setLocalStorageItems({ JDPS: 1 });
								store.dispatch(setJobDetailsActiveStep(1));
							}}
							style={{ cursor: isJobDetailsStepNotComplete(0) ? 'default' : 'pointer' }}
						>
							Job Description
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={getStepIcon}
							onClick={() => {
								if (isJobDetailsStepNotComplete(1)) return;
								Utility.setLocalStorageItems({ JDPS: 2 });
								store.dispatch(setJobDetailsActiveStep(2));
							}}
							style={{ cursor: isJobDetailsStepNotComplete(1) ? 'default' : 'pointer' }}
						>
							Job Details
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={getStepIcon}
							onClick={() => {
								if (isJobDetailsStepNotComplete(2)) return;
								Utility.setLocalStorageItems({ JDPS: 3 });
								store.dispatch(setJobDetailsActiveStep(3));
							}}
							style={{ cursor: isJobDetailsStepNotComplete(2) ? 'default' : 'pointer' }}
						>
							Preferred Experience
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							onClick={() => {
								if (isJobDetailsStepNotComplete(3)) return;
								Utility.setLocalStorageItems({ JDPS: 4 });
								store.dispatch(setJobDetailsActiveStep(4));
							}}
							style={{ cursor: isJobDetailsStepNotComplete(3) ? 'default' : 'pointer' }}
							StepIconComponent={getStepIcon}
						>
							Work Location
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							onClick={() => {
								if (isJobDetailsStepNotComplete(4)) return;
								Utility.setLocalStorageItems({ JDPS: 5 });
								store.dispatch(setJobDetailsActiveStep(5));
							}}
							style={{ cursor: isJobDetailsStepNotComplete(4) ? 'default' : 'pointer' }}
							StepIconComponent={getStepIcon}
						>
							Job Q&A
						</StepLabel>
					</Step>
				</Stepper>
			)}
			{isJobSkillsActive && (
				<Stepper activeStep={jobSkillsIntake.activeStep - 1} alternativeLabel>
					<Step>
						<StepLabel
							StepIconComponent={getStepIcon}
							onClick={() => {
								if (isJobSkillsStepNotComplete(0)) return;
								Utility.setLocalStorageItems({ JSPS: 1 });
								store.dispatch(setJobSkillsActiveStep(1));
							}}
							style={{ cursor: isJobSkillsStepNotComplete(0) ? 'default' : 'pointer' }}
						>
							Job Description
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={getStepIcon}
							onClick={() => {
								if (isJobSkillsStepNotComplete(1)) return;
								Utility.setLocalStorageItems({ JSPS: 2 });
								store.dispatch(setJobSkillsActiveStep(2));
							}}
							style={{ cursor: isJobSkillsStepNotComplete(1) ? 'default' : 'pointer' }}
						>
							Skills
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={getStepIcon}
							onClick={() => {
								if (isJobSkillsStepNotComplete(2)) return;
								Utility.setLocalStorageItems({ JSPS: 3 });
								store.dispatch(setJobSkillsActiveStep(3));
							}}
							style={{ cursor: isJobSkillsStepNotComplete(2) ? 'default' : 'pointer' }}
						>
							Skill Rankings
						</StepLabel>
					</Step>
					<Step>
						<StepLabel
							StepIconComponent={getStepIcon}
							onClick={() => {
								if (isJobSkillsStepNotComplete(3)) return;
								Utility.setLocalStorageItems({ JSPS: 4 });
								store.dispatch(setJobSkillsActiveStep(4));
							}}
							style={{ cursor: isJobSkillsStepNotComplete(3) ? 'default' : 'pointer' }}
						>
							Skill Story
						</StepLabel>
					</Step>
				</Stepper>
			)}
			<VerticalSpacer height={3} />
			{renderForm()}
		</div>
	);
};

JobAssessmentModal.propTypes = {
	setJobProfile: PropTypes.func,
	getJobDescriptions: PropTypes.func,
};

JobAssessmentModal.defaultProps = {
	setJobProfile: () => null,
	getJobDescriptions: () => null,
};

export default JobAssessmentModal;
