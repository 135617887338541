import React, { useEffect, useState } from 'react';
import { useStore } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, CircularProgress, Box } from '@material-ui/core';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessmentActions, useAssessment } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';

const WelcomeForm = () => {
	const pageNum = 0;
	const [isLoading, setIsLoading] = useState(true);
	const { id: listingID } = useParams();
	const store = useStore();
	const { listing, jobDetailsIntake, jobSkillsIntake } = useAssessment();
	const {
		addCompletedJobDetailsStep,
		setJobDetailsActiveStep,
		addCompletedJobSkillsStep,
		setJobSkillsActiveStep,
		setListing,
	} = useAssessmentActions();

	const nextStep = path => {
		if (path === 'jobDetails') {
			addCompletedJobDetailsStep(pageNum);
			setJobDetailsActiveStep(pageNum + 1);
		}
		if (path === 'jobSkills') {
			addCompletedJobSkillsStep(pageNum);
			setJobSkillsActiveStep(pageNum + 1);
		}
	};

	// Initialize the page data
	useEffect(() => {
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			setListing(response.data.data);
			setIsLoading(false);
		});
	}, []);

	if (isLoading || Object.keys(listing)?.length === 0) {
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	}
	return (
		<Fence centered width="44" className="text-center">
			<VerticalSpacer height={3} />
			<Typography variant="h1" className="font-lato-bold" gutterBottom>
				Welcome{listing.contacts?.length >= 1 ? ` ${listing.contacts[0]?.first_name}` : ''}!
			</Typography>
			<Typography variant="subtitle1" component="p" gutterBottom>
				Please complete the job profile for <strong>{listing.position_title}</strong>. The detail you provide will help
				our team more accurately match candidates.
			</Typography>
			<VerticalSpacer height={1} />
			<Typography variant="subtitle1" component="p" gutterBottom>
				Note - Your NauWork account team may have already entered some of the information on your behalf.
			</Typography>
			<VerticalSpacer height={3} />
			<Box display="flex" justifyContent="center" alignItems={'center'}>
				{jobDetailsIntake.activeStep !== null && (
					<Button variant="contained" color="primary" onClick={() => nextStep('jobDetails')}>
						Enter Job Details
					</Button>
				)}
				{jobSkillsIntake.activeStep !== null && (
					<Button variant="contained" color="primary" onClick={() => nextStep('jobSkills')}>
						Enter Job Skills
					</Button>
				)}
			</Box>
			<VerticalSpacer height={6} />
		</Fence>
	);
};

WelcomeForm.propTypes = {};

WelcomeForm.defaultProps = {};

export default WelcomeForm;
