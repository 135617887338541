import React, { useEffect, useState, useMemo } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid, CircularProgress, ButtonBase } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import PlusIcon from '../../../icons/PlusIcon';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import LabeledField from '../../../form/LabeledField';
import ElasticSkillDropdown from '../../../form/dropdowns/ElasticSkillDropdown';
import LoadingButton from '../../../form/LoadingButton';
import SuggestSkillForm from '../SuggestSkillForm';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessment, useAssessmentActions } from '../../../../store/Assessment';
import * as SiteAPI from '../../../../scripts/site';
import * as ListingsAPI from '../../../../scripts/listings';
import * as Utility from '../../../../scripts/utility';

const JobSkillsForm = () => {
	const pageNum = 2;
	const store = useStore();
	const [isLoading, setIsLoading] = useState(false);
	const [showSuggestSkillForm, setShowSuggestSkillForm] = useState(false);
	const [selectedMissingSkills, setSelectedMissingSkills] = useState([]);
	const [currentMoreSkillsPage, setCurrentMoreSkillsPage] = useState(1);
	const [latestSkillData, setLatestSkillData] = useState(null);
	// const [validSkills, setValidSkills] = useState([]);
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, jobSkillsIntake, matchedSkills, suggestedSkills } = useAssessment();
	const {
		setJobSkillsIsSaving,
		setJobSkillsCanProceed,
		addCompletedJobSkillsStep,
		setJobSkillsActiveStep,
		updateListingSkills,
		setListing,
		setMatchedSkills,
		removeMatchedSkill,
		addSuggestedSkill,
	} = useAssessmentActions();
	const paginatedMoreSkills = useMemo(() => {
		const pageSize = 20;
		return suggestedSkills.slice(0, pageSize * currentMoreSkillsPage);
	}, [suggestedSkills, currentMoreSkillsPage]);

	// const skillOptions = useMemo(
	// 	() =>
	// 		validSkills.filter(option => {
	// 			return !(listing.skills.find(s => s.skill_id === option.id) && matchedSkills.find(s => s.id === option.id));
	// 		}),
	// 	[listing.skills, validSkills, matchedSkills],
	// );

	const addMissingSkills = () => {
		const newSkills = selectedMissingSkills;
		addMissingSkills(newSkills);
		setSelectedMissingSkills([]);
	};

	const prevStep = () => setJobSkillsActiveStep(pageNum - 1);

	const nextStep = () => {
		setJobSkillsIsSaving(true);
		store.dispatch(SiteActions.hideAlert());

		const removedSkills = Utility.uniqueIDs(
			listing.skills?.filter(s => !matchedSkills.find(ms => ms.id === s.skill_id)),
		).map(s => ({
			skill_id: s.id,
			skill_experience_id: null,
			experience_years: 0,
		}));
		const newSkills = Utility.uniqueIDs(
			matchedSkills.filter(s => !listing.skills?.find(cs => cs.skill_id === s.id)),
		).map(s => ({
			skill_id: s.id,
			skill_experience_id: 1,
			experience_years: 0,
		}));
		const retainedSkills = Utility.uniqueIDs(listing.skills.filter(s => matchedSkills.find(ms => ms.id === s.skill_id)))
			.map(s => ({
				skill_id: s.id,
				skill_experience_id: 1,
				experience_years: 0,
			}))
			.map(s => {
				const rankedSkill = listing.skills.find(rs => rs.skill_id === s.skill_id && rs.skill_experience_id);
				const newSkill = rankedSkill ? { ...s, skill_experience_id: rankedSkill.skill_experience_id } : s;
				return newSkill;
			});

		console.log({
			removedSkills,
			newSkills,
			retainedSkills,
		});

		ListingsAPI.addListingSkills(listingID, [...removedSkills, ...newSkills, ...retainedSkills], response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				setJobSkillsIsSaving(false);
				return;
			}
			updateListingSkills(response.data.data);
			addCompletedJobSkillsStep(pageNum);
			SiteAPI.updateSkills(store);
			setJobSkillsActiveStep(pageNum + 1);
		});
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		setJobSkillsIsSaving(false);
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			setListing(response.data.data);
			setMatchedSkills(
				Utility.uniqueIDs(matchedSkills.concat((response.data.data.skills || []).map(skill => skill.skill))),
			);
			setIsLoading(false);
		});
		// if (!validSkills.length) {
		// 	ListingsAPI.getValidSkills(listingID, response => {
		// 		if (response) setValidSkills(response.data.data);
		// 	});
		// }
	}, []);

	// Check if can proceed
	useEffect(() => {
		const canProceed = matchedSkills.length >= 1;
		if (canProceed !== jobSkillsIntake.canProceed) {
			setJobSkillsCanProceed(canProceed);
		}
	}, [matchedSkills]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Skills
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Build your comprehensive job skill list by adding or removing skills to the <strong>Job Skills</strong> section.
			</Typography>
			<VerticalSpacer height={3} />
			<LabeledField label="Job Skills" />
			<Fence>
				{matchedSkills.map(skill => (
					<Chip key={skill.id} label={skill.label} onDelete={() => removeMatchedSkill(skill.id)} color="primary" />
				))}
				{matchedSkills.length === 0 ? 'None' : null}
			</Fence>
			<VerticalSpacer height={3} />
			<LabeledField label="Suggested Skills" />
			<Fence>
				{paginatedMoreSkills.length > 0
					? paginatedMoreSkills.map(skill => (
							<Chip key={skill.id} label={skill.label} onClick={() => addSuggestedSkill(skill.id)} />
					  ))
					: 'None'}
				{suggestedSkills.length > paginatedMoreSkills.length && (
					<Typography variant="h2" style={{ display: 'inline' }}>
						...
					</Typography>
				)}
			</Fence>
			{suggestedSkills.length > paginatedMoreSkills.length ? (
				<ButtonBase className="button-show-more" onClick={() => setCurrentMoreSkillsPage(p => p + 1)}>
					<PlusIcon />
					VIEW MORE
				</ButtonBase>
			) : null}
			<VerticalSpacer height={3} />
			<LabeledField label="Add Missing Skills">
				<Grid container spacing={2}>
					<Grid item xs={7}>
						<ElasticSkillDropdown
							filterListingId={listingID}
							tags
							variant="outlined"
							color="primary"
							name="skills_expert"
							// override={skillOptions}
							filter={skillId => {
								return !(listing.skills.find(s => s.skill_id === skillId) && matchedSkills.find(s => s.id === skillId));
							}}
							value={selectedMissingSkills?.map(x => x.id || x)}
							onChange={(ev, skillData) => {
								if (skillData) {
									setLatestSkillData(skillData);
								}
								const newSkills = ev.target.value?.map(x => (skillData || latestSkillData)[x])?.filter(x => x);
								setSelectedMissingSkills(newSkills);
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							color="primary"
							className="no-margins"
							disabled={!selectedMissingSkills.length}
							onClick={() => addMissingSkills()}
						>
							Add
						</Button>
					</Grid>
				</Grid>
				<VerticalSpacer height={0.5} />
				<ButtonBase onClick={() => setShowSuggestSkillForm(s => !s)} className="button-text-muted">
					Don&lsquo;t See a Skill You&lsquo;re Looking For?
				</ButtonBase>
			</LabeledField>
			{showSuggestSkillForm && (
				<SuggestSkillForm onClose={() => setShowSuggestSkillForm(false)} listingID={listingID} />
			)}
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={() => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" disabled={jobSkillsIntake.isSaving} onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					size="large"
					loading={jobSkillsIntake.isSaving}
					disabled={!jobSkillsIntake.canProceed || jobSkillsIntake.isSaving}
					onClick={nextStep}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

JobSkillsForm.propTypes = {};

JobSkillsForm.defaultProps = {};

export default JobSkillsForm;
