import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, RadioGroup, Radio, FormControlLabel, CircularProgress, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import { DocumentEmbed } from '../../../../pages/candidates/CandidateBlocks/ViewDocumentModal';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import LoadingButton from '../../../form/LoadingButton';
import UploadIcon from '../../../icons/UploadIcon';
import CheckmarkIcon from '../../../icons/CheckmarkCircleIcon';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessment, useAssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';
import * as Utility from '../../../../scripts/utility';
import AddAnotherButton from '../../../form/AddAnotherButton';
import DynamicTextEditor from '../../../TextEditor';
import ExperienceIcon from '../../../icons/ExperienceIcon';

const JobDescriptionForm = ({ path }) => {
	const pageNum = 1;
	const { id: listingID } = useParams();

	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { listing, jobDetailsIntake, jobSkillsIntake } = useAssessment();
	const {
		setListing,
		updateListing,

		setJobDetailsIsSaving,
		addCompletedJobDetailsStep,
		setJobDetailsActiveStep,
		setJobDetailsCanProceed,

		setJobSkillsIsSaving,
		addCompletedJobSkillsStep,
		setJobSkillsActiveStep,
		setJobSkillsCanProceed,

		setMatchedSkills,
		setSuggestedSkills,
	} = useAssessmentActions();

	const [isLoading, setIsLoading] = useState(false);
	const [jobDescriptions, setJobDescriptions] = useState([]);
	const [uploadOrPasteJobOrder, setUploadOrPasteJobOrder] = useState('upload'); // 'upload' or 'paste'
	const [descriptionShareLink, setDescriptionShareLink] = useState(null);
	const [isUploadTabShown, setIsUploadTabShown] = useState(!listing.description && !listing.descriptionFile);
	const [isDocumentUploadSuccessful, setIsDocumentUploadSuccessful] = useState(false);
	const [textFile, setTextFile] = useState('');
	const [isDocumentUploading, setIsDocumentUploading] = useState(false);
	const [displaySavedText, setDisplaySavedText] = useState(false);

	const refreshListing = async () => {
		ListingsAPI.getListingDescriptions(
			listingID,
			{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
			response => {
				if (!response) return;
				setJobDescriptions(response.data.data);
				ListingsAPI.createListingDescriptionShareLink({
					listingId: listingID,
					documentId: response.data.data[0].document_id,
				}).then(shareLink => {
					setDescriptionShareLink(shareLink?.data?.url);
				});
			},
		);
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			const forgedListing = {
				...response.data.data,
				office_locations: response.data.data.office_locations?.length
					? response.data.data.office_locations
					: [{ city: null, state_id: null }],
			};
			setListing(forgedListing);
		});
	};

	const onJobOrderUpload = (response, err) => {
		if (!response) {
			setJobDetailsIsSaving(false);
			setJobSkillsIsSaving(false);
			if (Utility.getFromObj(err, 'response', 'data', 'errors', 'file')) {
				store.dispatch(
					SiteActions.showAlert(
						`An error ocurred saving the description.
						${Utility.getFromObj(err, 'response', 'data', 'errors', 'file', 0)}`,
						'error',
					),
				);
			} else {
				store.dispatch(SiteActions.showAlert('An error ocurred saving the description. Please try again.', 'error'));
			}
			return;
		}
		const moreSkills = [
			...(response?.data?.more_skills || []),
			...(response?.data?.view_more_1 || []),
			...(response?.data?.view_more_2 || []),
			...(response?.data?.view_more_3 || []),
		];
		// Store in local storage in case we refresh the page
		localStorage.setItem(
			'intake-skills',
			JSON.stringify({
				priority_skills: response?.data?.priority_skills,
				more_skills: moreSkills,
				expires: Date.now() + 3600000,
			}),
		);
		if (Utility.getFromObj(response, 'data', 'priority_skills')) {
			setMatchedSkills(response.data.priority_skills);
		}
		if (Utility.getFromObj(response, 'data', 'more_skills')) {
			setSuggestedSkills(moreSkills);
		}
		if (path === 'jobDetails') {
			addCompletedJobDetailsStep(pageNum);
			setJobDetailsActiveStep(pageNum + 1);
			setJobDetailsIsSaving(false);
			setJobSkillsActiveStep(null);
		}
		if (path === 'jobSkills') {
			addCompletedJobSkillsStep(pageNum);
			setJobSkillsActiveStep(pageNum + 1);
			setJobSkillsIsSaving(false);
			setJobDetailsActiveStep(null);
		}
	};

	const prevStep = () => {
		if (path === 'jobDetails') {
			setJobDetailsActiveStep(pageNum - 1);
		}
		if (path === 'jobSkills') {
			setJobSkillsActiveStep(pageNum - 1);
		}
	};

	const nextStep = () => {
		if (
			(path === 'jobDetails' && !jobDetailsIntake.canProceed) ||
			(path === 'jobSkills' && !jobSkillsIntake.canProceed)
		) {
			store.dispatch(SiteActions.showAlert('Please fill out all required fields.', 'error'));
			return;
		}
		if (uploadOrPasteJobOrder === 'paste' && textFile) {
			updateListing('description', textFile);
		}
		store.dispatch(SiteActions.hideAlert());
		if (path === 'jobDetails') {
			setJobDetailsIsSaving(true);
		}
		if (path === 'jobSkills') {
			setJobSkillsIsSaving(true);
		}
		const forgedListing = ListingsAPI.getForgedListing(listing);
		ListingsAPI.updateListing(listingID, forgedListing, response => {
			if (!response) {
				setJobDetailsIsSaving(false);
				setJobSkillsIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			if (listing.descriptionFile) {
				ListingsAPI.uploadListingJobOrder(listingID, listing.descriptionFile, onJobOrderUpload);
			} else if (uploadOrPasteJobOrder === 'paste' && textFile && isUploadTabShown) {
				updateListing('description', textFile);
				ListingsAPI.uploadListingTextJobOrder(listingID, textFile, onJobOrderUpload);
			} else onJobOrderUpload(true);
		});
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		setJobDetailsIsSaving(false);
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			const forgedListing = {
				...response.data.data,
				office_locations: response.data.data.office_locations?.length
					? response.data.data.office_locations
					: [{ city: null, state_id: null }],
			};
			setListing(forgedListing);
			setIsLoading(false);
		});
		ListingsAPI.getListingDescriptions(
			listingID,
			{ per_page: 1, orderBy: 'sovren_parsed_docs.created_at', direction: 'desc' },
			response => {
				if (!response) {
					store.dispatch(SiteActions.showAlert('Could not load the listing. Refresh the page to try again.', 'error'));
					return;
				}
				setJobDescriptions(response.data.data);
				ListingsAPI.createListingDescriptionShareLink({
					listingId: listingID,
					documentId: response.data.data[0].document_id,
				}).then(shareLink => {
					setDescriptionShareLink(shareLink?.data?.url);
				});
			},
		);
	}, []);

	// Check if can proceed
	useEffect(() => {
		const canProceed = Boolean(listing.descriptionFile || listing.description);
		if (path === 'jobDetails' && canProceed !== jobDetailsIntake.canProceed) {
			setJobDetailsCanProceed(canProceed);
		}
		if (path === 'jobSkills' && canProceed !== jobSkillsIntake.canProceed) {
			setJobSkillsCanProceed(canProceed);
		}
	}, [listing]);

	const description = jobDescriptions?.[0];

	function getUploadIcon() {
		if (isDocumentUploading) return null;
		if (isDocumentUploadSuccessful) return CheckmarkIcon;
		return UploadIcon;
	}

	function getUploadText() {
		if (isDocumentUploading) return 'UPLOADING...';
		if (isDocumentUploadSuccessful) return 'UPLOAD SUCCESSFUL';
		return 'UPLOAD FILE';
	}

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ExperienceIcon size={32} className="teal-100" />
				{path === 'jobDetails' && (
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						Job Details
					</Typography>
				)}
				{path === 'jobSkills' && (
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						Job Skills
					</Typography>
				)}
			</div>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Job Description
			</Typography>
			{path === 'jobDetails' && (
				<Typography variant="subtitle1" component="p" gutterBottom>
					Please upload the job description file or select the copy/paste option to enter the job description text.
				</Typography>
			)}
			{path === 'jobSkills' && (
				<Typography variant="subtitle1" component="p" gutterBottom>
					To help us establish an initial pool of skills for this role please upload the job description file or select
					the copy/past option and enter the job description text.
				</Typography>
			)}
			<VerticalSpacer height={3} />
			<div style={{ display: 'flex', justifyContent: 'center' }}>
				<div style={{ width: '80%' }}>
					<div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', width: '100%' }}>
						<div style={{ display: 'flex', width: '65%' }}>
							{jobDescriptions?.map(_description => (
								<Typography key={_description.document_id}>
									Job Description <i>{_description.file_name}</i> was added{' '}
									<i>{Utility.formatDate(_description.updated_at, false, 'MMMM D, YYYY')}</i>
								</Typography>
							))}
						</div>
						{!isUploadTabShown && (
							<AddAnotherButton text="Add New Job Description" onClick={() => setIsUploadTabShown(true)} type="add" />
						)}
						{isUploadTabShown && (
							<AddAnotherButton
								text="Keep Existing Job Description"
								onClick={() => setIsUploadTabShown(false)}
								type="remove"
							/>
						)}
					</div>
					<VerticalSpacer height={3} />
					<DocumentEmbed
						style={{ display: isUploadTabShown ? 'none' : 'block' }}
						height={600}
						useRichTextView
						fileName={description?.file_name ?? ''}
						textFallback={listing?.description ?? ''}
						shareLink={descriptionShareLink}
						onLoadEnd={() => {
							console.log('onLoadEnd');
							setIsDocumentUploading(false);
							if (isUploadTabShown) {
								setIsDocumentUploadSuccessful(true);
								const timer = setTimeout(() => {
									setIsDocumentUploadSuccessful(false);
									clearTimeout(timer);
								}, 3000);
							}
						}}
					/>
					{isUploadTabShown && (
						<>
							<RadioGroup
								row
								aria-label="Upload or paste text?"
								name="upload_or_paste_job_order"
								onChange={e => setUploadOrPasteJobOrder(e.currentTarget.value)}
								value={uploadOrPasteJobOrder}
							>
								<FormControlLabel value="upload" control={<Radio color="primary" />} label="Upload Job Description" />
								<FormControlLabel
									value="paste"
									control={<Radio color="primary" />}
									label="Copy &amp; Paste Job Description"
								/>
							</RadioGroup>
							<VerticalSpacer height={1} />
							{uploadOrPasteJobOrder === 'upload' && (
								<DropzoneAreaBase
									name="descriptionFile"
									dropzoneText={getUploadText()}
									Icon={getUploadIcon()}
									disabled={isDocumentUploading || isDocumentUploadSuccessful}
									dropzoneClass="file-upload-dropzone"
									onAdd={async files => {
										setIsDocumentUploading(true);
										updateListing('descriptionFile', files[0].file);
										await ListingsAPI.uploadListingJobOrder(listingID, files[0].file, refreshListing);
									}}
									filesLimit={1}
									showAlerts={false}
									showPreviewsInDropzone={false}
									onLoadEnd={() => {
										setIsDocumentUploading(false);
										const timer = setTimeout(() => {
											setIsDocumentUploadSuccessful(false);
											clearTimeout(timer);
										}, 3000);
									}}
									style={{ width: '10%' }}
								/>
							)}
							{uploadOrPasteJobOrder === 'paste' && (
								<>
									<DynamicTextEditor
										fieldName="description"
										content={textFile}
										onChange={e => {
											const newValue = e.target.value;
											setTextFile(newValue);
											if (window.saveTimer) {
												clearTimeout(window.saveTimer);
											}
											if (window.displaySavedTimer) {
												clearTimeout(window.displaySavedTimer);
											}
											setDisplaySavedText(false);
											window.saveTimer = setTimeout(async () => {
												setDisplaySavedText(true);
												window.displaySavedTimer = setTimeout(() => {
													setDisplaySavedText(false);
												}, 1250);
											}, 1000);
										}}
									/>
									<Typography
										variant="body2"
										style={{
											textAlign: 'end',
											marginTop: 15,
											marginBottom: -20,
											fontStyle: 'italic',
											opacity: displaySavedText ? 100 : 0,
											transition: '.5s opacity ease',
										}}
									>
										✓ Saved
									</Typography>
								</>
							)}
						</>
					)}
				</div>
			</div>
			<VerticalSpacer height={3} />
			{isLoading && (
				<div style={{ textAlign: 'center' }}>
					<CircularProgress />
					<VerticalSpacer height={6} />
				</div>
			)}
			{alert.show ? (
				<Alert severity={alert.color} onClose={ev => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button
					variant="outlined"
					color="primary"
					size="large"
					disabled={jobDetailsIntake.isSaving || jobSkillsIntake.isSaving || isDocumentUploading}
					onClick={prevStep}
				>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					size="large"
					loading={jobDetailsIntake.isSaving || jobSkillsIntake.isSaving}
					disabled={
						(path === 'jobDetails' && !jobDetailsIntake.canProceed) ||
						(path === 'jobSkills' && !jobSkillsIntake.canProceed) ||
						jobDetailsIntake.isSaving ||
						jobSkillsIntake.isSaving
					}
					onClick={nextStep}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

JobDescriptionForm.propTypes = { path: PropTypes.string };

JobDescriptionForm.defaultProps = { path: null };

export default JobDescriptionForm;
