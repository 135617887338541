import React, { useState, useEffect, useMemo } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, TextField, Grid, CircularProgress, ButtonBase, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import LabeledField from '../../../form/LabeledField';
import Dropdown from '../../../form/dropdowns/Dropdown';
import StatesDropdown from '../../../form/dropdowns/StatesDropdown';
import WorkLocationDropdown from '../../../form/dropdowns/WorkLocationDropdown';
import LoadingButton from '../../../form/LoadingButton';
import PlusIcon from '../../../icons/PlusIcon';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessment, useAssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';
import * as Utility from '../../../../scripts/utility';
import ExperienceIcon from '../../../icons/ExperienceIcon';
import DynamicTextEditor from '../../../TextEditor';

const WorkLocationForm = () => {
	const pageNum = 4;
	const store = useStore();
	const [isLoading, setIsLoading] = useState(false);
	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, jobDetailsIntake } = useAssessment();
	const {
		updateListing,
		setJobDetailsActiveStep,
		setJobDetailsCanProceed,
		setJobDetailsIsSaving,
		addCompletedJobDetailsStep,
	} = useAssessmentActions();

	const hasLocalSelection = useMemo(() => {
		const preferenceIds = listing?.work_location_preferences?.map(p => (p.id ? p.id : p));
		const hasLocal = preferenceIds?.some(pid => [2, 3, 4, 5].includes(pid));
		return hasLocal;
	}, [listing]);

	const setListing = ev => updateListing(ev.target.name, ev.target.value);

	const setListingLocations = (index, name, value) => {
		const updatedOfficeLocations = [...listing.office_locations];
		updatedOfficeLocations[index] = { ...(listing.office_locations || [])[index], [name]: value };
		updateListing('office_locations', updatedOfficeLocations);
	};

	const addOfficeLocation = () => {
		const updatedOfficeLocations = [...(listing.office_locations || []), { city: null, state_id: null }];
		updateListing('office_locations', updatedOfficeLocations);
	};

	const prevStep = () => setJobDetailsActiveStep(pageNum - 1);

	const nextStep = () => {
		if (!jobDetailsIntake.canProceed) {
			store.dispatch(SiteActions.showAlert('Please fill out all required fields.', 'error'));
			return;
		}
		store.dispatch(SiteActions.hideAlert());
		setJobDetailsIsSaving(true);
		const forgedListing = ListingsAPI.getForgedListing(listing);
		ListingsAPI.updateListing(listingID, forgedListing, response => {
			if (!response) {
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			addCompletedJobDetailsStep(pageNum);
			setJobDetailsActiveStep(pageNum + 1);
		});
		setJobDetailsIsSaving(false);
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		setJobDetailsIsSaving(false);
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			const forgedListing = {
				...response.data.data,
				office_locations: response.data.data.office_locations?.length
					? response.data.data.office_locations
					: [{ city: null, state_id: null }],
			};
			setListing(forgedListing);
			setIsLoading(false);
		});
	}, []);

	// Check if can proceed
	useEffect(() => {
		const canProceed = Boolean(
			// 1. Must have at least one work location preference
			listing?.work_location_preferences?.length > 0 &&
				// 2. Must either offer relocation OR have remote work (id: 1) selected
				(listing?.offer_relocation || listing?.work_location_preferences?.map(p => (p.id ? p.id : p)).includes(1)) &&
				// 3. Must have remote states selected if remote work is chosen
				(listing?.remote_states?.length > 0 ||
					!listing?.work_location_preferences?.map(p => (p.id ? p.id : p)).includes(1)) &&
				// 4. Must have complete office locations if any non-remote options are selected
				(listing?.office_locations?.every(location => Boolean(location.city) && Boolean(location.state_id)) ||
					listing?.work_location_preferences?.map(p => (p.id ? p.id : p)).every(p => p === 1)) &&
				// 5. Must have additional details that isn't empty
				listing.additional_work_location_details &&
				listing.additional_work_location_details !== '<p></p>',
		);
		if (canProceed !== jobDetailsIntake.canProceed) {
			setJobDetailsCanProceed(canProceed);
		}
	}, [listing]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ExperienceIcon size={32} className="teal-100" />
				<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
					Job Details
				</Typography>
			</div>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Work Location Preferences
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item xs={5}>
						<LabeledField
							label="Work Locations"
							info="Select the work location options that are applicable to this role."
							labelStyle={{ fontWeight: '500', fontSize: 14 }}
						>
							<WorkLocationDropdown
								variant="outlined"
								name="work_location_preferences"
								value={listing?.work_location_preferences?.map(item => (item.id ? item.id : item))}
								placeholder={listing?.work_location_preferences?.length > 0 ? '' : 'Select all that apply'}
								onChange={setListing}
								fullWidth
								multiple
								renderOption={option => {
									return (
										<div>
											<Typography bold className="text-bold grey-300">
												{option.label}
											</Typography>
											<Typography bold className="grey-300">
												{option.description}
											</Typography>
										</div>
									);
								}}
							/>
						</LabeledField>
						<LabeledField label="Offer Candidate Relocation" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
							<Dropdown
								variant="outlined"
								name="offer_relocation"
								data={data.offerRelocation}
								value={hasLocalSelection ? listing.offer_relocation : null}
								placeholder="Choose from our list"
								onChange={setListing}
								disabled={listing.work_location_preferences?.length === 0 || !hasLocalSelection}
								fullWidth
							/>
						</LabeledField>
					</Grid>
					<Grid item xs={1} />
					<Grid item xs={5}>
						<LabeledField label="States Approved For Remote Workers" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
							<StatesDropdown
								variant="outlined"
								name="remote_states"
								placeholder={listing?.remote_states?.length > 0 ? '' : 'Select all that apply'}
								value={listing?.remote_states?.map(item => (item.state_id ? item.state_id : item))}
								onChange={setListing}
								// disabled unless remote is selected in work_location_preferences
								disabled={!listing.work_location_preferences?.map(p => (p.id ? p.id : p)).includes(1)}
								fullWidth
								multiple
								useID
								autoComplete="off"
							/>
						</LabeledField>
						<LabeledField label="Eligible Office Sites" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
							{(listing.office_locations?.length ? listing.office_locations : [{ city: null, state_id: null }]).map(
								(location, index) => (
									// eslint-disable-next-line react/no-array-index-key
									<Grid container spacing={2} key={`listing-location-fields-${index}`}>
										<Grid item xs={8}>
											<TextField
												variant="outlined"
												name="city"
												placeholder="City"
												value={Utility.getFromObj(listing, 'office_locations')[index]?.city || ''}
												onChange={e => setListingLocations(index, e.target.name, e.target.value)}
												// disabled unless any local preference is selected
												disabled={!hasLocalSelection}
												fullWidth
											/>
										</Grid>
										<Grid item xs={4}>
											<StatesDropdown
												variant="outlined"
												name="state_id"
												placeholder="State"
												value={Utility.getFromObj(listing, 'office_locations')[index]?.state_id}
												onChange={e => setListingLocations(index, e.target.name, e.target.value)}
												// disabled unless any local preference is selected
												disabled={!hasLocalSelection}
												fullWidth
												useAbbreviation
												useID
											/>
										</Grid>
									</Grid>
								),
							)}
							<VerticalSpacer height={0.5} />
							<ButtonBase
								className="button-show-more"
								onClick={addOfficeLocation}
								disabled={!hasLocalSelection}
								style={{ textTransform: 'uppercase' }}
							>
								<PlusIcon />
								add location
							</ButtonBase>
						</LabeledField>
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Additional Detail
			</Typography>
			<VerticalSpacer height={2} />
			<LabeledField
				label="Additional Location Details"
				labelStyle={{ fontWeight: '500', fontSize: 14 }}
				enableMinimize
				minimized={Boolean(listing.additional_work_location_details)}
			>
				<DynamicTextEditor
					fieldName="additional_work_location_details"
					content={listing.additional_work_location_details || ''}
					onChange={setListing}
				/>
			</LabeledField>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={() => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" disabled={jobDetailsIntake.isSaving} onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					size="large"
					loading={jobDetailsIntake.isSaving}
					disabled={!jobDetailsIntake.canProceed || jobDetailsIntake.isSaving}
					onClick={nextStep}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

WorkLocationForm.propTypes = {};

WorkLocationForm.defaultProps = {};

export default WorkLocationForm;
