import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, Chip, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import LoadingButton from '../../../form/LoadingButton';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import YearsDropdown from '../../../form/dropdowns/YearsDropdown';
import StarBadgeIcon from '../../../icons/StarBadgeIcon';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessment, useAssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';

const HeroSkillsExperienceForm = ({ prevStep, setStoryStep }) => {
	const store = useStore();
	const { alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, jobSkillsIntake } = useAssessment();
	const { setJobSkillsCanProceed, setJobSkillsIsSaving, updateListingSkills } = useAssessmentActions();

	const skillStoryName = 'hero_skill';
	const heroSkills = useMemo(() => listing?.skills?.filter(s => s[skillStoryName]), [listing.skills]);

	const setSkillExperience = (skill, experienceYears) => {
		const updatedSkills = listing.skills.map(s =>
			s.skill_id === skill.skill_id ? { ...skill, experience_years: experienceYears } : s,
		);
		updateListingSkills(updatedSkills);
	};

	const nextStep = () => {
		store.dispatch(SiteActions.hideAlert());
		setJobSkillsIsSaving(true);
		ListingsAPI.addListingSkills(listingID, listing.skills, response => {
			if (!response) {
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				setJobSkillsIsSaving(false);
				return;
			}
			updateListingSkills(response.data.data);
			setJobSkillsIsSaving(false);
			setStoryStep(s => s + 1);
		});
	};

	// Check if can proceed
	useEffect(() => {
		const canProceed = !heroSkills.find(s => !s.experience_years);
		if (canProceed !== jobSkillsIntake.canProceed) {
			setJobSkillsCanProceed(canProceed);
		}
	}, [heroSkills]);

	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Your Skill Story <span className="color-muted">2 of 4</span>
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Let&rsquo;s further refine the job skills into categories that will help generate the most precise candidate
				matches - <strong>Hero</strong> skills.
			</Typography>
			<VerticalSpacer height={2} />
			<Fence width={60}>
				<Grid container spacing={0}>
					<Grid item className="text-center">
						<StarBadgeIcon className="teal-100" size={26} style={{ marginTop: 4, marginRight: 15 }} />
					</Grid>
					<Grid item xs={11}>
						<Typography variant="subtitle2" className="subtitle-2-bold">
							Hero Skills
						</Typography>
						<Typography>Please select the desired minimum experience level for each hero skill.</Typography>
						<VerticalSpacer height={2} />
						{heroSkills.map(skill => (
							<Grid container spacing={2} key={skill.skill_id}>
								<Grid item xs={4}>
									<Chip label={skill.skill.label} color="primary" />
								</Grid>
								<Grid item xs={4}>
									<div style={{ display: 'flex', alignItems: 'center' }}>
										<Typography variant="label" style={{ marginRight: 10 }}>
											Experience
										</Typography>
										<YearsDropdown
											variant="outlined"
											name=""
											value={skill.experience_years}
											onChange={e => setSkillExperience(skill, e.target.value)}
											fullWidth
										/>
									</div>
								</Grid>
							</Grid>
						))}
					</Grid>
				</Grid>
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={() => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" disabled={jobSkillsIntake.isSaving} onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					size="large"
					loading={jobSkillsIntake.isSaving}
					disabled={!jobSkillsIntake.canProceed}
					onClick={nextStep}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

HeroSkillsExperienceForm.propTypes = {
	prevStep: PropTypes.func,
	setStoryStep: PropTypes.func,
};

HeroSkillsExperienceForm.defaultProps = {
	prevStep: () => null,
	setStoryStep: () => null,
};

export default HeroSkillsExperienceForm;
