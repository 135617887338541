import React from 'react';
import { Typography } from '@material-ui/core';
import { RichTextReadOnly } from 'mui-tiptap';
import FormattedText from '../../../components/form/FormattedText';
import { useExtensions } from '../../../components/TextEditor';

export const DocumentEmbed = ({
	height,
	textFallback,
	shareLink,
	fileName,
	onLoadEnd,
	style,
	useRichTextView = false,
}) => {
	const extensions = useExtensions({
		placeholder: 'Job Description',
	});
	if (!shareLink)
		return (
			<Typography
				style={{
					opacity: 0.5,
					fontStyle: 'italic',
				}}
			>
				No document to display.
			</Typography>
		);

	return (
		<div style={style}>
			{fileName?.includes('.pdf') ? (
				// <iframe
				// 	ref={embedRef}
				// 	title="embed"
				// 	src={`https://docs.google.com/viewer?embedded=true&url=${encodeURIComponent(shareLink)}`}
				// 	width="100%"
				// 	height={height}
				// 	frameBorder="0"
				// 	style={{
				// 		height,
				// 	}}
				// />
				<iframe
					title="resume"
					height={height}
					width="100%"
					src={shareLink}
					type="application/pdf"
					onLoad={() => {
						onLoadEnd?.();
					}}
				/>
			) : // <iframe title="resume" height={height} width="100%" src={shareLink} type="application/pdf" />
			fileName?.includes('.txt') && textFallback && useRichTextView ? (
				<div style={{ backgroundColor: '#f5f5f5', padding: '10px', borderRadius: '5px' }}>
					<RichTextReadOnly content={textFallback} extensions={extensions} />
				</div>
			) : fileName?.includes('.txt') && textFallback && !useRichTextView ? (
				<FormattedText
					height={20}
					style={{
						whiteSpace: 'normal',
						maxHeight: 'none',
					}}
				>
					{/* <Typography variant="body2">{job.description || Utility.nb}</Typography> */}
					{/* eslint-disable-next-line react/no-danger */}
					<div
						className="public_description"
						style={{ maxWidth: 600 }}
						dangerouslySetInnerHTML={{
							__html: textFallback,
						}}
					/>
				</FormattedText>
			) : (
				<iframe
					title="embed"
					src={`https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(shareLink)}`}
					width="100%"
					height={height}
					style={{ height }}
				/>
			)}
		</div>
	);
};

const ViewDocumentModal = ({ shareLink, document }) => {
	const height = 'calc(100vh - 125px)';
	return (
		<div style={{ textAlign: 'center' }}>
			<Typography variant="h5" style={{ marginBottom: 20 }}>
				{document?.file_name}
			</Typography>
			{shareLink && <DocumentEmbed height={height} shareLink={shareLink} fileName={document?.file_name} />}
		</div>
	);
};

export default ViewDocumentModal;
