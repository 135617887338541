/**
 * Checks if the listing has a job description uploaded
 * @param {Object} l - The listing object
 * @param {Array} descriptions - Array of uploaded job description documents
 * @returns {boolean} True if listing has a job description, false otherwise
 *
 * Checks for:
 * - Job description file uploaded (listing.descriptionFile)
 * - Job description text entered directly (listing.description)
 * - Previously uploaded job descriptions exist (descriptions array has items)
 */
export const description = (l, descriptions) => {
	return Boolean(l.descriptionFile || l.description || descriptions?.length > 0);
};

/**
 * Checks if the listing has complete job details information
 * @param {Object} l - The listing object
 * @returns {boolean} True if listing has complete job details, false otherwise
 *
 * Checks for:
 * - Position title specified
 * - Start date specified
 * - Role type specified (e.g. full-time, contract)
 * - Number of openings specified (can be 0)
 * - Minimum salary specified
 * - Maximum salary specified
 * - At least one other compensation type selected (e.g. bonus, equity)
 * - At least one value proposition selected
 * - Travel willingness specified
 * - Work visa requirements specified
 * - Additional job details provided and not empty
 */
export const details = l => {
	return Boolean(
		l.position_title &&
			l.start_date &&
			l.role_type &&
			// openings can be 0, so we need to check != null
			l.openings != null &&
			l.salary_rate_min &&
			l.salary_rate_max &&
			l.other_compensation_types?.length > 0 &&
			l.value_proposition_types?.length > 0 &&
			l.travel_willingness_type_id &&
			l.work_visa_type_id &&
			l.additional_job_detail &&
			l.additional_job_detail !== '<p></p>',
	);
};

/**
 * Checks if the listing has complete experience requirements
 * @param {Object} l - The listing object
 * @returns {boolean} True if listing has complete experience info, false otherwise
 *
 * Checks for:
 * - Years of professional experience specified
 * - At least one industry selected
 * - Education type specified (e.g. Bachelor's, Master's)
 * - At least one field of study selected
 * - Leadership allocation type specified (% time spent managing)
 * - Total years of leadership experience specified
 * - Additional experience details provided and not empty
 */
export const experience = l => {
	return Boolean(
		l.professional_experience_years != null &&
			l.industries?.length > 0 &&
			l.education_type_id &&
			l.study_types?.length > 0 &&
			l.leadership_allocation_type_id &&
			l.total_leadership_years != null &&
			l.additional_experience_details &&
			l.additional_experience_details !== '<p></p>',
	);
};

/**
 * Checks if the listing has complete work location information
 * @param {Object} l - The listing object
 * @returns {boolean} True if listing has complete work location info, false otherwise
 *
 * Checks for:
 * - At least one work location preference selected
 * - If remote work (id=1) is selected, must have remote states
 * - If not remote, must have office locations with city and state
 * - Must either offer relocation or allow remote work
 * - Must have additional location details that aren't empty
 */
export const location = l => {
	return Boolean(
		l?.work_location_preferences?.length > 0 &&
			(l?.offer_relocation || l?.work_location_preferences?.map(p => (p.id ? p.id : p)).includes(1)) &&
			(l?.remote_states?.length > 0 || !l?.work_location_preferences?.map(p => (p.id ? p.id : p)).includes(1)) &&
			(l?.office_locations?.every(loc => Boolean(loc.city) && Boolean(loc.state_id)) ||
				l?.work_location_preferences?.map(p => (p.id ? p.id : p)).every(p => p === 1)) &&
			l.additional_work_location_details &&
			l.additional_work_location_details !== '<p></p>',
	);
};

/**
 * Checks if the listing has any screening questions
 * @param {Object} l - The listing object
 * @returns {boolean} True if listing has screening questions, false otherwise
 */
export const qa = l => {
	return Boolean(l.job_qa_questions?.length > 0);
};

/**
 * Checks if the listing has skills with experience level 5
 * @param {Object} l - The listing object
 * @returns {boolean} True if listing has skills with experience level 5, false otherwise
 */
export const skills = l => {
	return Boolean(l.skills?.find(s => s.skill_experience_id === 5));
};

/**
 * Checks if both required skills and hero skills arrays have at least one skill
 * @param {Array} requiredSkills - Array of required skills for the job
 * @param {Array} heroSkills - Array of hero/key skills for the job
 * @returns {boolean} True if both arrays have at least one skill, false otherwise
 */
export const skillStory = (requiredSkills, heroSkills) => {
	return Boolean([requiredSkills, heroSkills].every(arr => arr?.length > 0));
};
