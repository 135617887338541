import React from 'react';
import { useDispatch } from 'react-redux';
import { Grid, Typography, Button } from '@material-ui/core';
import LabeledField from '../../../components/form/LabeledField';
import LightbulbIcon from '../../../components/icons/SectionIcons/LightbulbIcon';
import ViewBlockWrapper from '../../candidates/CandidateBlocks/ViewBlockWrapper';
import { ActionCreators as SiteActions } from '../../../store/Site';
import EditQuestionsModal from './EditScreeningQuestionsModal';
import { Panel, PanelHeader } from '../../../components/Panel';

const Block = ({ job, setJob, minimized, setMinimized, isComplete }) => {
	const dispatch = useDispatch();
	return (
		<Panel id="ScreeningQuestions">
			<PanelHeader className="spread">
				<div style={{ display: 'flex', alignItems: 'center' }}>
					<LightbulbIcon size={32} className="teal-100" />
					<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
						Screening Questions
					</Typography>
				</div>
				<Button
					variant="contained"
					color="primary"
					size="small"
					onClick={() => {
						dispatch(
							SiteActions.showModal(<EditQuestionsModal job={job} setJob={setJob} />, {
								className: 'modal-medium modal-no-padding modal-no-shadow',
							}),
						);
					}}
				>
					{isComplete ? 'Edit' : 'Complete'}
				</Button>
			</PanelHeader>
			<ViewBlockWrapper
				icon={LightbulbIcon}
				iconColor="var(--blue-500)"
				title="Screening Questions"
				minimized={minimized}
				setMinimized={setMinimized}
				onEditClick={() => {
					dispatch(
						SiteActions.showModal(<EditQuestionsModal job={job} setJob={setJob} />, {
							className: 'modal-medium modal-no-padding modal-no-shadow',
						}),
					);
				}}
			>
				<>
					<Grid item xs={12}>
						{job?.screening_questions?.map(group => {
							return (
								<>
									<Typography variant="h6">{group?.group_label || 'Unnamed'}</Typography>
									<ul
										style={{
											paddingLeft: 15,
										}}
									>
										{group?.group_questions?.map(question => {
											return (
												<li>
													<Typography variant="body2">{question?.label}</Typography>
												</li>
											);
										})}
									</ul>
								</>
							);
						})}
					</Grid>
				</>
			</ViewBlockWrapper>
		</Panel>
	);
};

export default Block;
