import React, { useState, useEffect } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, CircularProgress, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import LoadingButton from '../../../form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessment, useAssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';
import ExperienceIcon from '../../../icons/ExperienceIcon';
import QADropdown from '../../../form/dropdowns/QADropdown';
import AddAnotherButton from '../../../form/AddAnotherButton';

const JobQAForm = () => {
	const pageNum = 5;
	const store = useStore();
	const [isLoading, setIsLoading] = useState(false);
	const { alert, data } = useSelector(state => state.site, shallowEqual);
	const [qaPopper, setQaPopper] = useState({ open: false, anchor: null });
	const { id: listingID } = useParams();
	const { listing, jobDetailsIntake } = useAssessment();
	const {
		setListing: setListingFromStore,
		updateListing,
		setJobDetailsIsSaving,
		addCompletedJobDetailsStep,
		setJobDetailsIsComplete,
		setJobSkillsActiveStep,
		setJobDetailsCanProceed,
		setJobDetailsActiveStep,
		resetJobDetailsIntake,
	} = useAssessmentActions();
	const initialListing = JSON.parse(JSON.stringify(listing));
	initialListing.job_qa_questions = initialListing.job_qa_questions?.map((q, index) => ({ ...q, id: index }));
	const [updatedListing, setUpdatedListing] = useState(initialListing);
	const [nextId, setNextId] = useState(listing.job_qa_questions?.length || 0);
	const [qaNumFields, setQaNumFields] = useState(updatedListing?.job_qa_questions?.length || 1);

	const setListing = ev => updateListing(ev.target.name, ev.target.value);

	const prevStep = () => setJobSkillsActiveStep(pageNum - 1);

	const nextStep = () => {
		if (!jobDetailsIntake.canProceed) {
			store.dispatch(SiteActions.showAlert('Please fill out all required fields.', 'error'));
			return;
		}
		store.dispatch(SiteActions.hideAlert());
		setJobDetailsIsSaving(true);
		// ! TODO: THIS IS  NOT WORKING YET
		// const forgedListing = ListingsAPI.getForgedListing(listing);
		// ListingsAPI.updateListing(listingID, forgedListing, response => {
		// 	if (!response) {
		// 		console.log('response', response);
		// 		setJobDetailsIsSaving(false);
		// 		store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
		// 		return;
		// 	}
		// 	addCompletedJobDetailsPage(pageNum);
		// 	setJobDetailsIsComplete(true);
		// 	setJobDetailsActiveStep(null);
		// 	setJobSkillsActiveStep(0);
		// });
		addCompletedJobDetailsStep(pageNum);
		setJobDetailsIsComplete(true);
		setJobDetailsActiveStep(null);
		setJobSkillsActiveStep(0);
		setJobDetailsIsSaving(false);
		resetJobDetailsIntake();
	};

	const handleSaveOrUpdateQuestion = (id, questionId, answer) => {
		setUpdatedListing(prevListing => {
			const listingQaQuestions = prevListing.job_qa_questions?.map(q =>
				q.id === id ? { ...q, qa_question_id: questionId, answer } : q,
			);
			if (!listingQaQuestions?.some(q => q.id === id)) {
				listingQaQuestions?.push({ id, qa_question_id: questionId, answer });
			}
			return { ...prevListing, job_qa_questions: listingQaQuestions };
		});
	};

	const handleDeleteQuestion = id => {
		setUpdatedListing(prevListing => {
			const updatedQuestions = prevListing.job_qa_questions?.filter(q => q.id !== id);
			return { ...prevListing, job_qa_questions: updatedQuestions };
		});
	};

	const handleAddQuestion = () => {
		setUpdatedListing(prevListing => ({
			...prevListing,
			job_qa_questions: [
				...prevListing.job_qa_questions,
				{ id: prevListing.job_qa_questions?.length, qa_question_id: '', answer: '' },
			],
		}));
		setNextId(prevId => prevId + 1);
		setQaNumFields(prevCount => prevCount + 1);
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		setJobDetailsIsSaving(false);
		try {
			ListingsAPI.getListing(listingID, response => {
				if (!response) {
					store.dispatch(
						SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
					);
					setIsLoading(false);
					return;
				}
				const forgedListing = {
					...response.data.data,
					office_locations: response.data.data.office_locations?.length
						? response.data.data.office_locations
						: [{ city: null, state_id: null }],
				};
				setListingFromStore(forgedListing);
				setIsLoading(false);
			});
		} catch (error) {
			setIsLoading(false);
		}
	}, []);

	// Check if can proceed
	useEffect(() => {
		if (listing.job_qa_questions?.length > 0) {
			setJobDetailsCanProceed(true);
		}
	}, [listing]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ExperienceIcon size={32} className="teal-100" />
				<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
					Job Details
				</Typography>
			</div>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Job Q&A
			</Typography>
			<VerticalSpacer height={3} />
			<Fence>
				{(updatedListing.job_qa_questions?.length > 0
					? updatedListing.job_qa_questions
					: [{ id: nextId, qa_question_id: '', answer: '' }]
				).map((q, idx) => (
					<QADropdown
						key={q.id}
						data={data}
						onComplete={handleSaveOrUpdateQuestion}
						onDelete={handleDeleteQuestion}
						listing={updatedListing}
						question={q}
						idx={idx}
						isLastQuestion={updatedListing.job_qa_questions?.length <= 1}
					/>
				))}
				{qaNumFields < 7 && (
					<div style={{ display: 'flex', justifyContent: 'flex-end' }}>
						<AddAnotherButton text="Add Another Q&A" onClick={handleAddQuestion} />
					</div>
				)}
			</Fence>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={() => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" disabled={jobDetailsIntake.isSaving} onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					size="large"
					loading={jobDetailsIntake.isSaving}
					disabled={!jobDetailsIntake.canProceed}
					onClick={nextStep}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

JobQAForm.propTypes = {};

JobQAForm.defaultProps = {};

export default JobQAForm;
