import React, { useEffect, useState } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, Button, CircularProgress, ButtonBase, Grid } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import * as Icons from '@material-ui/icons';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import { RadioButton, RadioButtonGroup } from '../../../form/RadioButton';
import LoadingButton from '../../../form/LoadingButton';
import ElasticSkillDropdown from '../../../form/dropdowns/ElasticSkillDropdown';
import PlusIcon from '../../../icons/PlusIcon';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessment, useAssessmentActions, ActionCreators as AssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';
import * as Utility from '../../../../scripts/utility';

const SkillRankingsForm = () => {
	const pageNum = 3;
	const store = useStore();
	const [isLoading, setIsLoading] = useState(false);
	const [showTopAddSkillsForm, setShowTopAddSkillsForm] = useState(false);
	const [showBottomAddSkillsForm, setShowBottomAddSkillsForm] = useState(false);
	const [selectedMissingSkills, setSelectedMissingSkills] = useState([]);
	const [latestSkillData, setLatestSkillData] = useState(null);
	// const [validSkills, setValidSkills] = useState([]);
	const [currentSkillPage, setCurrentSkillPage] = useState(0);
	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, jobSkillsIntake } = useAssessment();
	const {
		setListing,
		setJobSkillsActiveStep,
		setJobSkillsIsSaving,
		addCompletedJobSkillsStep,
		updateListingSkills,
		updateListingSkill,
		setJobSkillsCanProceed,
	} = useAssessmentActions();

	const [originalListing, setOriginalListing] = useState(null);

	// const skillOptions = useMemo(
	// 	() =>
	// 		validSkills.filter(option => {
	// 			return !listing.skills.find(s => s.skill_id === option.id);
	// 		}),
	// 	[listing.skills, validSkills],
	// );

	const paginatedSkills = [];
	const chunkSize = 20;
	const experienceLevelSelectedSkills = listing.skills?.filter(x => x.experience || x.skill_experience_id);
	for (let i = 0; i < experienceLevelSelectedSkills?.length; i += chunkSize) {
		const chunk = experienceLevelSelectedSkills?.slice(i, i + chunkSize);
		paginatedSkills.push(chunk);
	}

	const addMissingSkills = () => {
		const newSkills = selectedMissingSkills.map(skill => ({
			skill_id: skill.id,
			skill_experience_id: 1,
			experience_years: 0,
			label: skill.label,
			skill: {
				id: skill.id,
				name: skill.label,
			},
		}));
		const updatedSkills = [...listing.skills, ...newSkills];
		updateListingSkills(updatedSkills);
		setSelectedMissingSkills([]);
	};

	const removeSkill = skill => {
		const updatedSkills = listing.skills.filter(s => s.skill_id !== skill.skill_id);
		updateListingSkills(updatedSkills);
	};

	const updateSkill = ev => {
		updateListingSkill(ev.target.name, ev.target.value);
	};

	const prevStep = () => setJobSkillsActiveStep(pageNum - 1);

	const nextStep = () => {
		const valid = listing.skills.every(s => s.skill_experience_id !== 1);
		if (!valid) return;
		setJobSkillsIsSaving(true);
		const removedSkills = Utility.uniqueIDs(
			originalListing.skills.filter(s => !listing?.skills?.find(cs => cs.skill_id === s.skill_id)),
		).map(s => ({
			skill_id: s.skill_id,
			skill_experience_id: null,
			experience_years: 0,
		}));

		const skillPostArray = [...(listing.skills || []), ...(removedSkills || [])];

		ListingsAPI.addListingSkills(listingID, skillPostArray, response => {
			if (!response) {
				setJobSkillsIsSaving(false);
				SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error');
				return;
			}
			addCompletedJobSkillsStep(pageNum);
			setJobSkillsActiveStep(pageNum + 1);
		});
	};

	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		setJobSkillsIsSaving(false);
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			setListing(response.data.data);

			setOriginalListing(response.data.data);
			setIsLoading(false);
		});
		// if (!validSkills.length) {
		// 	ListingsAPI.getValidSkills(listingID, response => {
		// 		if (response) setValidSkills(response.data.data);
		// 	});
		// }
	}, []);

	useEffect(() => {
		const canProceed = (listing.skills || []).every(s => s.skill_experience_id !== 1);
		if (canProceed !== jobSkillsIntake.canProceed) {
			setJobSkillsCanProceed(true);
		}
	}, [listing.skills]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);
	return (
		<>
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Skill Rankings
			</Typography>
			<Typography variant="subtitle2" component="p" gutterBottom>
				Please define the baseline candidate ranking you desire for each skill.
			</Typography>
			<VerticalSpacer height={1} />
			<Typography>
				<strong>1 - Basic:</strong> Candidate ranks skill level as novice aptitude.
				<br />
				<strong>2 - Intermediate:</strong> Candidate ranks skill level between novice and advanced.
				<br />
				<strong>3 - Advanced:</strong> Candidate ranks skill level as high-aptitude.
				<br />
				<strong>4 - Best:</strong> Candidate ranks the skill as a highest aptitude skill.
				<br />
			</Typography>
			<VerticalSpacer height={3} />
			<ButtonBase className="button-show-more" onClick={() => setShowTopAddSkillsForm(s => !s)}>
				<PlusIcon />
				ADD MISSING SKILLS
			</ButtonBase>
			{showTopAddSkillsForm && (
				<Grid container spacing={1}>
					<Grid item xs={4}>
						<ElasticSkillDropdown
							filterListingId={listingID}
							tags
							variant="outlined"
							color="primary"
							name="skills_expert"
							// override={skillOptions}
							filter={skillId => {
								return !listing.skills.find(s => s.skill_id === skillId);
							}}
							value={selectedMissingSkills?.map(x => x.id || x)}
							onChange={(ev, skillData) => {
								if (skillData) {
									setLatestSkillData(skillData);
								}
								const newSkills = ev.target.value?.map(x => (skillData || latestSkillData)[x])?.filter(x => x);

								setSelectedMissingSkills(newSkills);
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							color="primary"
							className="no-margins"
							disabled={!selectedMissingSkills.length}
							onClick={() => addMissingSkills()}
						>
							Add
						</Button>
					</Grid>
				</Grid>
			)}
			<VerticalSpacer height={2} />
			<Grid container spacing={1}>
				{paginatedSkills[currentSkillPage]?.map(skill => (
					<Grid item xs={6}>
						<Typography className="text-medium grey-600">
							{skill?.label || Utility.getFromObj(skill, 'skill', 'label')}{' '}
							<ButtonBase className="button-cancel" onClick={() => removeSkill(skill)}>
								<Icons.Cancel style={{ marginTop: -3 }} />
							</ButtonBase>
						</Typography>
						<VerticalSpacer height={0.75} />
						<RadioButtonGroup
							name={skill.skill.name}
							value={skill.skill_experience_id}
							// error={validate && skill.skill_experience_id === 1}
							onChange={updateSkill}
						>
							{data.skillExperienceLevels
								.filter(level => level.name !== 'uncategorized')
								.map((level, i) => (
									<RadioButton key={level.id} title={i + 1} value={level.id}>
										{level.label}
									</RadioButton>
								))}
						</RadioButtonGroup>
						<VerticalSpacer height={2} />
					</Grid>
				))}
			</Grid>
			<VerticalSpacer height={3} />
			<ButtonBase className="button-show-more" onClick={() => setShowBottomAddSkillsForm(s => !s)}>
				<PlusIcon />
				ADD MISSING SKILLS
			</ButtonBase>
			{showBottomAddSkillsForm && (
				<Grid container spacing={2}>
					<Grid item xs={7}>
						<ElasticSkillDropdown
							filterListingId={listingID}
							tags
							variant="outlined"
							color="primary"
							name="skills_expert"
							// override={skillOptions}
							filter={skillId => {
								return !listing.skills.find(s => s.skill_id === skillId);
							}}
							value={selectedMissingSkills?.map(x => x.id || x)}
							onChange={(ev, skillData) => {
								if (skillData) {
									setLatestSkillData(skillData);
								}
								const newSkills = ev.target.value?.map(x => (skillData || latestSkillData)[x])?.filter(x => x);

								setSelectedMissingSkills(newSkills);
							}}
							fullWidth
						/>
					</Grid>
					<Grid item xs={2}>
						<Button
							variant="contained"
							color="primary"
							className="no-margins"
							disabled={!selectedMissingSkills.length}
							onClick={() => addMissingSkills()}
						>
							Add
						</Button>
					</Grid>
				</Grid>
			)}
			<VerticalSpacer height={3} />
			<div className="text-center">
				{paginatedSkills?.map((_, i) => (
					<Button
						// eslint-disable-next-line react/no-array-index-key
						key={`skill-pagination-btn-${i}`}
						variant={currentSkillPage === i ? 'contained' : 'outlined'}
						color="primary"
						style={{ width: 44, minWidth: 44, height: 44, paddingTop: 13, margin: '0 5px' }}
						onClick={() => setCurrentSkillPage(i)}
					>
						{i + 1}
					</Button>
				))}
			</div>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={() => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button
					variant="outlined"
					color="primary"
					size="large"
					onClick={() => store.dispatch(AssessmentActions.setSkillsPage(2))}
				>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					size="large"
					loading={jobSkillsIntake.isSaving}
					disabled={!jobSkillsIntake.canProceed || jobSkillsIntake.isSaving}
					onClick={nextStep}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

SkillRankingsForm.propTypes = {};

SkillRankingsForm.defaultProps = {};

export default SkillRankingsForm;
