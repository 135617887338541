// import Axios from 'axios';
import API, { isStagingAPI } from './api';
import { demoJobMatches } from './demo';
import * as Utility from './utility';

export const getListings = (parameters, callback) => {
	const filters = parameters;

	console.log({ filters });

	if (filters.start_date_min) filters.start_date_min = Utility.formatAPIDate(filters.start_date_min);

	if (filters.start_date_max) filters.start_date_max = Utility.formatAPIDate(filters.start_date_max);

	const query = Utility.propsToURL(filters);

	API.get(`/listings`, { params: filters })
		.then(callback)
		.catch(err => callback(false, err));
};

export const getListing = (id, callback) => {
	API.get(`/listings/${id}`)
		.then(callback)
		.catch(err => callback(false, err));
};

export const updateListing = (id, parameters, callback) => {
	const listing = parameters;

	if (Utility.getFromObj(listing, 'start_date')) listing.start_date = Utility.formatAPIDate(listing.start_date);
	if (Utility.getFromObj(listing, 'role_level_preferences'))
		listing.role_level_preferences = listing.role_level_preferences.map(p => (p.id ? p.id : p));

	API.patch(`/listings/${id}`, listing)
		.then(callback)
		.catch(err => callback(false, err));
};

export const updateListingBypassIntake = (id, parameters, callback) => {
	const listing = parameters;

	API.patch(`/listings/${id}/bypass-intake`, listing)
		.then(callback)
		.catch(err => callback(false, err));
};

export const uploadListingJobOrder = async (id, file, callback) => {
	const form = new FormData();
	form.append('file', file);

	await API.post(`/listings/${id}/upload-job-order`, form)
		.then(callback)
		.catch(err => callback(false, err));
};

export const uploadListingTextJobOrder = async (id, text, callback) => {
	await API.post(`/listings/${id}/upload-text-job-order`, text)
		.then(callback)
		.catch(err => callback(false, err));
};

export const syncListingSkills = (id, skills, callback) => {
	API.patch(`/listings/${id}/skills/sync`, skills)
		.then(callback)
		.catch(err => callback(false, err));
};

export const getListingSkills = async id => {
	return API.get(`/listings/${id}/skills`);
};

export const addListingSkills = (id, skills, callback) => {
	// Force each skill to have an experience_years value.
	// In some cases we get skills with a null value, which throws an API error.
	const newSkills = skills
		// .filter(x => x.skill_experience_id !== undefined && x.skill_experience_id !== null)
		.map(skill => ({
			...skill,
			experience_years: skill.experience_years || 0,
		}));
	API.post(`/listings/${id}/skills`, newSkills)
		.then(callback)
		.catch(err => callback(false, err));
};

export const addListing = (parameters, callback) => {
	const listing = parameters;

	if (Utility.getFromObj(listing, 'start_date')) listing.start_date = Utility.formatAPIDate(listing.start_date);

	API.post(`/listings`, listing)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Deletes a listing record
 * @param {Number} id - The ID of the listing
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const deleteListing = (id, callback) => {
	API.delete(`/listings/${id}`)
		.then(callback)
		.catch(err => callback(false, err));
};

export const getContacts = (id, callback) => {
	API.get(`/listings/${id}/contacts`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Get the descriptions attached to a listing
 * @param {Number} id - The ID of the listing
 * @param {Object} parameters - Filters passed as query parameters
 * @param {Function} callback - The function called once AJAX call is finished
 */
export const getListingDescriptions = (id, parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.get(`/listings/${id}/descriptions${query}`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Download a resume an open it in browser
 * @param {Number} listingID - The listing's ID
 * @param {Number} documentID - The document's ID
 * @param {String} filename = The name of the file
 * @param {Function} callback - The callback function executed on response
 */
export const downloadListingDescription = (listingID, documentID, filename, callback) => {
	API.get(`/listings/${listingID}/descriptions/${documentID}/download`, { responseType: 'blob' })
		.then(response => {
			const file = new Blob([response.data], { type: response.headers['content-type'] });
			const fileURL = window.URL.createObjectURL(file);
			const link = document.createElement('a');
			link.href = fileURL;
			link.download = filename;
			link.click();

			if (typeof callback === 'function') callback(response);
		})
		.catch(err => callback(false, err));
};

export const sendAssessment = (id, callback) => {
	getContacts(id, (response, contactErr) => {
		if (!response) callback(false, contactErr);

		const contact = Utility.getJobContactField(response.data.data, 'id');

		if (!contact) callback(false, 'contact');

		API.post(`/contacts/${contact}/listings/${id}/send-assessment`)
			.then(callback)
			.catch(err => callback(false, err));
	});
};

export const markAssessmentComplete = (id, callback) => {
	API.post(`/listings/${id}/complete-assessment`)
		.then(callback)
		.catch(err => {
			callback(false, err);
		});
};

/**
 * Search for a listing by ID or name
 * @param {String | Number} query - The query string
 * @param {Function} callback - The callback function executed on response
 */
export const searchForListing = (query, callback) => {
	API.get(`/sync/listings/search`, { params: { query } })
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Sync a listing's data from Bullhorn
 * @param {Number} id - The listing's ID
 * @param {Object} parameters - The parameters for the request
 * @param {Function} callback - The callback function executed on response
 */
export const syncFromBullhorn = (id, parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.post(`/sync/listings/from-bullhorn/${id}${query}`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Sync a listing's data to Bullhorn
 * @param {Number} id - The listing's ID
 * @param {Object} parameters - The parameters for the request
 * @param {Function} callback - The callback function executed on response
 */
export const syncToBullhorn = (id, parameters, callback) => {
	const query = Utility.propsToURL(parameters);

	API.post(`/sync/listings/to-bullhorn/${id}${query}`)
		.then(callback)
		.catch(err => callback(false, err));
};

/**
 * Get a list of valid skills for a specific listing
 * @param {Number} id - The listing's ID
 * @param {Function} callback - The callback function executed on response
 */
export const getValidSkills = (id, callback) => {
	API.get(`/listings/${id}/available-skills`)
		.then(callback)
		.catch(err => callback(false, err));
};

// FUNCTIONS

/**
 * Returns an updated listing object with forged attributes for
 * work_location_preferences and remote_states
 * @param {Object} updatedListing - A listing object
 */
export const getForgedListing = updatedListing => {
	const workLocationPreferences = updatedListing.work_location_preferences?.map(p => (p.id ? p.id : p));
	const industries = updatedListing.industries?.map(p => (p.id ? p.id : p));
	const hasLocalSelection = workLocationPreferences?.some(pid => [2, 3, 4, 5].includes(pid));
	const hasRemoteSelection = workLocationPreferences?.includes(1);

	const forgedListing = {
		...updatedListing,
		other_compensation_types: (updatedListing.other_compensation_types || []).map(item => (item.id ? item.id : item)),
		work_location_preferences: workLocationPreferences,
		remote_states: hasRemoteSelection
			? updatedListing.remote_states?.map(item => {
					if (item.state_id) return item.state_id;
					if (item.id) return item.id;
					return item;
			  })
			: [],
		industries,
		offer_relocation: hasLocalSelection ? updatedListing.offer_relocation : null,
		office_locations: hasLocalSelection ? updatedListing.office_locations?.filter(l => l?.city && l?.state_id) : [],
		study_types: updatedListing?.study_types?.map(x => x?.id || x),
		// value_proposition_types: updatedListing.value_proposition_types?.map((p, i) => ({
		// 	value_proposition_type_id: p,
		// 	priority: i,
		// })),
	};

	return forgedListing;
};

export const getListingMatches = async ({ listingId }) => {
	const results = await API.get(`/listings/${listingId}/matches`);

	return results.data.data;
};

export const addListingMatch = async ({ candidateId, listingId, accessLevelId }) => {
	const matchList = [
		{
			candidate_id: candidateId,
			is_published: false,
			access_level_id: accessLevelId !== undefined ? accessLevelId : 1,
			// interest_level_id: 1,
		},
	];

	const newMatch = await API.post(`/listings/${listingId}/matches`, matchList);

	return newMatch;
};

export const deleteListingMatch = async ({ listingId, matchId }) => {
	await API.delete(`/listings/${listingId}/matches/${matchId}`);
};

export const updateListingMatch = async ({ listingId, matchId, match }) => {
	await API.patch(`/listings/${listingId}/matches/${matchId}`, match);
};

export const getClientCorporation = async ({ corporationId }) => {
	return API.get(`/client-corporations/${corporationId}`);
};

export const updateClientCorporation = (id, company) => {
	const newCompany = { ...company };
	newCompany.skills = newCompany.skills?.map(x => x.skill_id || x);

	if (newCompany?.business_sizes?.length) {
		newCompany.business_sizes = newCompany.business_sizes.map(x => x.id || x);
	}
	if (newCompany?.industries?.length) {
		newCompany.industries = newCompany.industries.map(x => x.id || x);
	}
	if (newCompany?.relationship_types?.length) {
		newCompany.relationship_types = newCompany.relationship_types.map(x => x.id || x);
	}
	if (newCompany?.vendors?.length) {
		newCompany.vendors = newCompany.vendors.map(x => x.id || x);
	}
	return API.patch(`/client-corporations/${id}`, newCompany);
};

export const getListingMatch = async ({ listingId, matchId }) => {
	return API.get(`/listings/${listingId}/matches/${matchId}`);
};

export const createListingMatchShareLink = async ({ listingId, matchId, match }) => {
	return API.post(`/listings/${listingId}/matches/${matchId}/share`);
};

export const getListingMatchShareLinks = async ({ listingId, matchId, match }) => {
	return API.get(`/listings/${listingId}/matches/${matchId}/shares`);
};

export const postListingMatchView = async ({ listingId, matchId }) => {
	return API.post(`/listings/${listingId}/matches/${matchId}/view`);
};

export const patchListingMatchInterest = async ({ listingId, matchId, interestLevelId }) => {
	return API.patch(`/listings/${listingId}/matches/${matchId}/rank`, { interest_level_id: interestLevelId });
};

export const sendMatchEmail = async ({ listingId, matchId }) => {
	return API.post(`/listings/${listingId}/matches/${matchId}/email`);
};

export const getListingDocuments = async ({ listingId }) => {
	return API.get(`/listings/${listingId}/documents`);
};

export const postDocument = async ({ listingId, file, type }) => {
	const form = new FormData();
	form.append('file', file);
	form.append('document_type_id', type);
	return API.post(`/listings/${listingId}/documents`, form);
};

export const createListingDescriptionShareLink = async ({ listingId, documentId }) => {
	return API.post(`/listings/${listingId}/descriptions/${documentId}/share`);
};

export const getListingGraphMatches = async ({ listingId }) => {
	const query = Utility.propsToURL({
		per_page: 100,
		page: 1,
	});

	// if (isStagingAPI) {
	// 	return new Promise(resolve => setTimeout(() => resolve({ data: demoJobMatches }), 3000));
	// }
	return API.get(`/listings/${listingId}/graph-matches${query}`);
};

export const getListingRandomGraphMatches = async ({ listingId }) => {
	const query = Utility.propsToURL({
		per_page: 100,
		page: 0,
	});

	// if (isStagingAPI) {
	// 	return new Promise(resolve => setTimeout(() => resolve({ data: demoJobMatches }), 3000));
	// }
	return API.get(`/listings/${listingId}/random-graph-matches${query}`);
};

export const allowShare = async ({ listingId }) => {
	return API.post(`/listings/${listingId}/allow-share`);
};

export const denyShare = async ({ listingId }) => {
	return API.post(`/listings/${listingId}/deny-share`);
};

export const rankMatchFit = async ({ listingId, candidateId, rankingId, comment, reasonIds }) => {
	const ranking = {
		candidate_id: candidateId,
		listing_id: listingId,
		match_fit_ranking_status_id: rankingId,
		comment,
		match_fit_ranking_reasons: reasonIds,
	};
	return API.post(`/match-fit/rankings`, ranking);
};

export const viewMatchFit = async ({ listingId, candidateId }) => {
	const data = {
		candidate_id: candidateId,
		listing_id: listingId,
	};
	return API.post(`/match-fit/view`, data);
};

export const deleteMatchFit = async matchFitRankId => {
	await API.delete(`/match-fit/rankings/${matchFitRankId}`);
};
