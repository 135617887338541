import React from 'react';
import { Button, Typography } from '@material-ui/core';
import { AddCircleOutline, RemoveCircleOutline } from '@material-ui/icons';

// variants = primary, textSecondary
// type = add, remove
const AddAnotherButton = ({ onClick, text = 'Add Another', variant = 'primary', type = 'add', disabled = false }) => (
	<Button
		variant="text"
		color="#000"
		size="small"
		style={{
			margin: 0,
			padding: 4,
			minWidth: 0,
			borderRadius: 8,
		}}
		onClick={onClick}
		disabled={disabled}
	>
		<div
			style={{
				display: 'flex',
				alignItems: 'center',
			}}
		>
			{type === 'add' && (
				<AddCircleOutline size={14} style={{ marginRight: 6, color: variant === 'primary' ? '#0076ff' : '#aaa' }} />
			)}
			{type === 'remove' && (
				<RemoveCircleOutline size={14} style={{ marginRight: 6, color: variant === 'primary' ? '#0076ff' : '#aaa' }} />
			)}
			<Typography variant="button" color={variant} style={{ marginTop: 1, fontWeight: '700' }}>
				{text}
			</Typography>
		</div>
	</Button>
);

export default AddAnotherButton;
