import React, { useState, useEffect } from 'react';
import { useStore, shallowEqual, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Typography, TextField, Grid, CircularProgress, Button } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import Fence from '../../../Fence';
import VerticalSpacer from '../../../VerticalSpacer';
import LabeledField from '../../../form/LabeledField';
import Dropdown from '../../../form/dropdowns/Dropdown';
import LoadingButton from '../../../form/LoadingButton';
import { ActionCreators as SiteActions } from '../../../../store/Site';
import { useAssessment, useAssessmentActions } from '../../../../store/Assessment';
import * as ListingsAPI from '../../../../scripts/listings';
import * as Utility from '../../../../scripts/utility';
import ExperienceIcon from '../../../icons/ExperienceIcon';
import DateInput from '../../../form/inputs/DateInput';
import JobOpeningsDropdown from '../../../form/dropdowns/JobOpeningsDropdown';
import CompensationTypesDropdown from '../../../form/dropdowns/CompensationTypesDropdown';
import ValuePropositionsDropdown from '../../../form/dropdowns/ValuePropositionsDropdown';
import TravelWillingnessDropdown from '../../../form/dropdowns/TravelWillingnessDropdown';
import WorkVisaDropdown from '../../../form/dropdowns/WorkVisaDropdown';
import DynamicTextEditor from '../../../TextEditor';
import SalaryRangeDropdown from '../../../form/dropdowns/SalaryRangeDropdown';

const JobDetailsForm = () => {
	const pageNum = 2;
	const store = useStore();
	const [isLoading, setIsLoading] = useState(false);
	const { data, alert } = useSelector(state => state.site, shallowEqual);
	const { id: listingID } = useParams();
	const { listing, jobDetailsIntake } = useAssessment();
	const {
		updateListing,

		setJobDetailsActiveStep,
		setJobDetailsIsSaving,
		addCompletedJobDetailsStep,
		setJobDetailsCanProceed,
	} = useAssessmentActions();

	const setListing = ev => updateListing(ev.target.name, ev.target.value);

	const prevStep = () => setJobDetailsActiveStep(pageNum - 1);

	const nextStep = () => {
		if (!jobDetailsIntake.canProceed) {
			store.dispatch(SiteActions.showAlert('Please fill out all required fields.', 'error'));
			return;
		}
		store.dispatch(SiteActions.hideAlert());
		setJobDetailsIsSaving(true);
		const forgedListing = ListingsAPI.getForgedListing(listing);
		ListingsAPI.updateListing(listingID, forgedListing, response => {
			if (!response) {
				setJobDetailsIsSaving(false);
				store.dispatch(SiteActions.showAlert('An error ocurred saving your information. Please try again.', 'error'));
				return;
			}
			addCompletedJobDetailsStep(pageNum);
			setJobDetailsActiveStep(pageNum + 1);
			setJobDetailsIsSaving(false);
		});
	};

	// Initialize the page data
	useEffect(() => {
		store.dispatch(SiteActions.hideAlert());
		setIsLoading(true);
		setJobDetailsIsSaving(false);
		ListingsAPI.getListing(listingID, response => {
			if (!response) {
				console.log('response', response);
				store.dispatch(
					SiteActions.showAlert('An error ocurred loading your information. Please refresh the page.', 'error'),
				);
				setIsLoading(false);
				return;
			}
			const forgedListing = {
				...response.data.data,
				office_locations: response.data.data.office_locations?.length
					? response.data.data.office_locations
					: [{ city: null, state_id: null }],
			};
			setListing(forgedListing);
			setIsLoading(false);
		});
	}, []);

	// Check if can proceed
	useEffect(() => {
		const canProceed = Boolean(
			listing.position_title &&
				listing.start_date &&
				listing.role_type &&
				// openings can be 0, so we need to check != null
				listing.openings != null &&
				listing.salary_rate_min &&
				listing.salary_rate_max &&
				listing.other_compensation_types?.length > 0 &&
				listing.value_proposition_types?.length > 0 &&
				listing.travel_willingness_type_id &&
				listing.work_visa_type_id &&
				listing.additional_job_detail &&
				listing.additional_job_detail !== '<p></p>',
		);
		if (canProceed !== jobDetailsIntake.canProceed) {
			setJobDetailsCanProceed(canProceed);
		}
	}, [listing]);

	if (isLoading)
		return (
			<div className="text-center">
				<CircularProgress />
			</div>
		);

	return (
		<>
			<div style={{ display: 'flex', alignItems: 'center' }}>
				<ExperienceIcon size={32} className="teal-100" />
				<Typography variant="h6" component="h2" className="text-medium" style={{ paddingLeft: 10 }}>
					Job Details
				</Typography>
			</div>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Role Details
			</Typography>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				<LabeledField label="Job Title" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<TextField
						variant="outlined"
						name="position_title"
						value={Utility.getFromObj(listing, 'position_title')}
						onChange={setListing}
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<Fence width={25}>
				<LabeledField label="Desired Start Date" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<DateInput
						variant="outlined"
						name="start_date"
						value={Utility.getFromObj(listing, 'start_date')}
						onChange={setListing}
						fullWidth
						placeholder="Choose from our list"
					/>
				</LabeledField>
			</Fence>
			<Fence width={25}>
				<LabeledField label="Employment Type" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<Dropdown
						variant="outlined"
						name="role_type"
						value={Utility.getFromObj(listing, 'role_type')}
						data={data.currentEmploymentTypes}
						onChange={setListing}
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<Fence width={25}>
				<LabeledField label="Job Openings" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<JobOpeningsDropdown
						variant="outlined"
						name="openings"
						value={listing.openings || ''}
						onChange={setListing}
						fullWidth
						placeholder="Choose from our list"
					/>
				</LabeledField>
			</Fence>
			<Fence width={25}>
				<LabeledField label="Salary Range" labelStyle={{ fontWeight: '500', fontSize: 14 }} removeMargin>
					<Grid container>
						<Grid item xs={5}>
							{/* TODO this keeps selecting the value one above the one you want */}
							<SalaryRangeDropdown
								variant="outlined"
								name="salary_rate_min"
								value={listing.salary_rate_min}
								onChange={ev => {
									const value = parseInt(Utility.getFromObj(data, 'salaryExpectationTypes', ev.target.value).name, 10);
									setListing({
										target: {
											name: ev.target.name,
											value,
										},
									});
								}}
								placeholder="Min"
								fullWidth
							/>
						</Grid>
						<Grid xs={2} />
						<Grid item xs={5}>
							{/* TODO this keeps selecting the value one above the one you want */}
							<SalaryRangeDropdown
								variant="outlined"
								name="salary_rate_max"
								value={listing.salary_rate_max}
								onChange={ev => {
									const value = parseInt(Utility.getFromObj(data, 'salaryExpectationTypes', ev.target.value).name, 10);
									setListing({
										target: {
											name: ev.target.name,
											value,
										},
									});
								}}
								placeholder="Max"
								fullWidth
							/>
						</Grid>
					</Grid>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={2} />
			<Fence width={25}>
				<LabeledField label="Other Compensation" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<CompensationTypesDropdown
						variant="outlined"
						name="other_compensation_types"
						value={listing.other_compensation_types?.map(t => (t.id ? t.id : t))}
						onChange={listing}
						placeholder={listing.other_compensation_types?.length > 0 ? '' : 'Select all that apply'}
						error={!listing.other_compensation_types?.length}
						fullWidth
						multiple
					/>
				</LabeledField>
			</Fence>
			<Fence width={25}>
				<LabeledField label="Value Propositions" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<ValuePropositionsDropdown
						variant="outlined"
						name="value_proposition_types"
						value={listing.value_proposition_types?.map(t =>
							t.value_proposition_type_id ? t.value_proposition_type_id : t,
						)}
						onChange={e => {
							setListing({
								target: {
									name: 'value_proposition_types',
									value: e?.target?.value?.map((id, i) => ({
										priority: i,
										value_proposition_type_id: id,
									})),
								},
							});
						}}
						placeholder={listing.value_proposition_types?.length > 0 ? '' : 'Select all that apply'}
						fullWidth
						multiple
					/>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={3} />
			<Fence width={25}>
				<LabeledField label="Travel Requirement" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<TravelWillingnessDropdown
						variant="outlined"
						name="travel_willingness_type_id"
						value={listing.travel_willingness_type_id}
						placeholder="Choose from our list"
						onChange={setListing}
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<Fence width={25}>
				<LabeledField label="Work Visa Sponsorship" labelStyle={{ fontWeight: '500', fontSize: 14 }}>
					<WorkVisaDropdown
						variant="outlined"
						name="work_visa_type_id"
						value={listing.work_visa_type_id}
						onChange={setListing}
						placeholder="Choose from our list"
						fullWidth
					/>
				</LabeledField>
			</Fence>
			<VerticalSpacer height={3} />
			<Typography variant="h2" className="font-lato-bold blue-600" gutterBottom>
				Additional Detail
			</Typography>
			<VerticalSpacer height={2} />
			<LabeledField
				label="Additional Job Details"
				labelStyle={{ fontWeight: '500', fontSize: 14 }}
				enableMinimize
				minimized={Boolean(listing.additional_job_detail)}
			>
				<DynamicTextEditor
					fieldName="additional_job_detail"
					content={listing.additional_job_detail ?? ''}
					onChange={setListing}
				/>
			</LabeledField>
			<VerticalSpacer height={3} />
			{alert.show ? (
				<Alert severity={alert.color} onClose={() => store.dispatch(SiteActions.hideAlert())}>
					{alert.message}
				</Alert>
			) : null}
			<Fence className="text-right">
				<Button variant="outlined" color="primary" size="large" disabled={jobDetailsIntake.isSaving} onClick={prevStep}>
					Back
				</Button>
				<LoadingButton
					variant="contained"
					color="primary"
					size="large"
					loading={jobDetailsIntake.isSaving}
					disabled={!jobDetailsIntake.canProceed || jobDetailsIntake.isSaving}
					onClick={nextStep}
				>
					Next
				</LoadingButton>
			</Fence>
		</>
	);
};

JobDetailsForm.propTypes = {};

JobDetailsForm.defaultProps = {};

export default JobDetailsForm;
